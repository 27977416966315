/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import { v4 as uuid } from 'uuid';

// Global callback function
let iframeAPIReady = false;
let youtubeBehaviors = new Map();
window.onYouTubeIframeAPIReady = function () {
  iframeAPIReady = true;
  for (let [behavior] of youtubeBehaviors.entries()) {
    if ('handleYouTubeIframeAPIReady' in behavior) {
      behavior.handleYouTubeIframeAPIReady();
    }
  }
};

const videoBehavior = createBehavior(
  'video',
  {
    bindUI() {
      this.ui = {};
      this.ui.cover = this.getChild('cover');
      this.ui.iframe = this.getChild('ytiframe');
      this.iframeID = 'player-' + uuid();
      this.ui.iframe.setAttribute('id', this.iframeID);
      this.ui.container = this.getChild('container');
    },
    bindEvents() {
      // Listen for onYouTubeIframeAPIReady
      youtubeBehaviors.set(this, true);

      if (this.ui.cover) {
        this.ui.cover.addEventListener('click', this.handleCoverClick);
      }
    },
    handleCoverClick() {
      // console.log('this.player', this.player);
      if (this.player) {
        this.player.playVideo();
        this.updateUI();
      }
    },
    handlePlayerReady(evt) {
      // this.player.playVideo();
    },
    handlePlayerStateChange() {
      let isPlaying = this.player?.getPlayerState() === 1 || false;
      if (isPlaying && !this.wasPlayed) {
        this.wasPlayed = true;
      }
      this.updateUI();
    },
    handleYouTubeIframeAPIReady() {
      this.player = new YT.Player(this.iframeID, {
        events: {
          onReady: this.handlePlayerReady,
          onStateChange: this.handlePlayerStateChange,
        },
      });
    },
    updateUI() {
      this.node.classList.toggle('is-playing', this.wasPlayed);
    },
    loadYouTubeIframeAPI() {
      // Loads the IFrame Player API code asynchronously.
      var tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    },
    unbindEvents() {
      // No longer listen for onYouTubeIframeAPIReady
      youtubeBehaviors.delete(this);
    },
  },
  {
    init() {
      this.player = null;
      this.wasPlayed = true;
      this.bindUI();
      this.bindEvents();

      if (iframeAPIReady) {
        // YouTube iframe is already ready, so no need to listen
        this.handleYouTubeIframeAPIReady();
      } else {
        this.loadYouTubeIframeAPI();
      }
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default videoBehavior;
