import createBehavior from '@js/functions/createBehavior.js';
import barba from '@barba/core';

const triggerFilterBehavior = createBehavior(
  'trigger-filter',
  {
    onChange() {
      const params = new URLSearchParams(location.search);

      if (this.node.checked) {
        params.set(this.options['filter-param'], this.options['filter-value']);
      } else {
        params.delete(this.options['filter-param'])
      }

      if (Array.from(params.keys()).length) {
        barba.go(`${location.pathname}?${params}`);
      } else {
        barba.go(`${location.pathname}`)
      }
    },
    bindEvents() {
      this.node.addEventListener('change', this.onChange)
    },
    unbindEvents() {
      this.node.removeEventListener('change', this.onChange)
    }
  },
  {
    init() {
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default triggerFilterBehavior;
