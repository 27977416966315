import createBehavior from '@js/functions/createBehavior.js';
import ListProduct from '@components/list/product/list-product.twig';
import lockScroll from '@js/utils/lock-scroll.js';

const cartMiniBehavior = createBehavior(
  'cart-mini',
  {
    bindUI() {
      this.ui = {};
      this.ui.element = this.getChild('element');
      this.ui.toggle = this.getChild('toggle');
      this.ui.close = this.getChild('close');
      this.ui.list = this.getChild('list');

      if (this.ui.element) {
        this.isOpen = this.ui.element.classList.contains(this.openKlass);
      }
    },
    bindEvents() {
      if (this.ui.toggle) {
        this.ui.toggle.addEventListener('click', this.toggle, false);
      }

      if (this.ui.close) {
        this.ui.close.addEventListener('click', this.close, false);
      }

      // Add event listener when cart is updated.
      document.addEventListener('cart:updated', this.cartUpdatedHandler, false);

      // Add event listener before page enter.
      document.addEventListener('page:beforeEnter', this.pageBeforeEnterHandler, false);

      // Close cart when nav is opened
      document.addEventListener('nav:opened', this.close, false)
    },
    rebindUIEvents() {
      // Unbind events.
      this.unbindEvents();

      // Get barba container.
      this.barbaContainer = document.querySelector('[data-barba="container"]:last-child');

      // Get elements.
      this.ui.element = this.barbaContainer.querySelector('[data-cart-mini-element]');
      this.ui.toggle = this.barbaContainer.querySelector('[data-cart-mini-toggle]');
      this.ui.close = this.barbaContainer.querySelector('[data-cart-mini-close]');
      this.ui.list = this.barbaContainer.querySelector('[data-cart-mini-list]');

      // Bind events.
      this.bindEvents();
    },
    getLocalStorage() {
      // Get cart from local storage.
      const cartFromLocalStorage = localStorage.getItem('cart');

      // If we don't have any local storage, return.
      if (!cartFromLocalStorage) return;

      // Get cart object.
      const cartObj = JSON.parse(cartFromLocalStorage);

      // If we have a validity and it's not expired.
      if (cartObj.validity && cartObj.validity >= Date.now() && cartObj.locale && cartObj.locale === document.documentElement.getAttribute('lang') && cartObj.products && cartObj.products.length) {

        // Save cart object.
        this.cart = cartObj;

        // Build cart mini.
        this.build();

        // Show cart.
        setTimeout(() => {
          this.show();
        }, 600);
      }
    },
    build(onlyLastProduct) {
      // Init items.
      let items = [];

      // If we want to show latest product added.
      if (onlyLastProduct && this.latestProductAdded) {
        items.push(this.latestProductAdded);
      } else if (this.cart.products && this.cart.products.length) {
        items = this.cart.products;
      }

      // Build list markup.
      const listMarkup = ListProduct({
        items: items,
        for_label: this.options['for-label'],
        locale: document.documentElement.getAttribute('lang'),
        variation: 'condensed'
      });

      // Append markup.
      this.ui.list.innerHTML = listMarkup;

      // Emit custom event
      document.dispatchEvent(new CustomEvent('page:updated'));

      // Calculate number of tickets.
      let numberOfTickets = 0;

      // If we have some products in the cart.
      if (this.cart.products && this.cart.products.length) {
        this.cart.products.forEach(product => {
          if (product.prices && product.prices.length) {
            product.prices.forEach(price => {
              if (price.quantity) {
                numberOfTickets += price.quantity;
              }
            });
          }
        });
      }

      // Append number of products inside button.
      this.ui.toggle.innerHTML = numberOfTickets;
    },
    show() {
      if (this.alreadyInit) {
        this.ui.element.classList.add('no-transition');
      }
      this.ui.element.classList.add(this.visibleKlass);
      this.alreadyInit = true;
    },
    hide() {
      this.ui.element.classList.remove(this.visibleKlass);
    },
    toggle() {
      // If there is a timer, remove it.
      if (this.timer) {
        clearTimeout(this.timer);
      }

      // If it's open.
      if (this.isOpen) {
        this.close();
      } else {
        this.open(true);
      }
    },
    open(clickOnToggle) {
      // Init onlyLastProduct variable.
      let onlyLastProduct = clickOnToggle ? false : true;

      // Build cart.
      this.build(onlyLastProduct);

      // Remove just-added class
      if (onlyLastProduct) {
        this.ui.element.classList.add(this.justAddedKlass);
      } else {
        this.ui.element.classList.remove(this.justAddedKlass);
      }

      this.ui.element.classList.add(this.openKlass);
      this.isOpen = true;
      lockScroll.enable(this.transitionDuration);

      // Emit event
      document.dispatchEvent(new CustomEvent('miniCart:opened'));

      // If there is a timer, remove it.
      if (this.timer) {
        clearTimeout(this.timer);
      }

      // If it's only added product, add timer.
      if (onlyLastProduct) {
        this.timer = setTimeout(() => {
          this.close();
        }, this.closeAfterDuration);
      }
    },
    close() {
      this.ui.element.classList.remove(this.openKlass);
      this.isOpen = false;

      // After it's closed
      setTimeout(() => {

        // Disable scroll.
        lockScroll.disable();

      }, this.transitionDuration);
    },
    pageBeforeEnterHandler(e) {
      // Rebind new UI and events.
      this.rebindUIEvents();

      // Disable lock scroll.
      lockScroll.disable();

      // Reset isOpen page.
      this.isOpen = false;

      // If cart is valid and we have some products
      if (this.cart && this.cart.validity && this.cart.validity >= Date.now() && this.cart.products && this.cart.products.length) {
        this.build();
        this.show();
      } else {
        this.hide();
      }
    },
    cartUpdatedHandler(e) {
      // Get new cart object.
      this.cart = e.detail ? e.detail.cart : {};
      this.latestProductAdded = e.detail ? e.detail.latestProductAdded : null;

      // Build cart.
      this.build(true);

      // Check if we have some product.
      if (!this.cart || !this.cart.products || this.cart.products.length === 0) {
        this.hide();
      } else {
        // If it's not visible.
        if (!this.ui.element.classList.contains(this.visibleKlass)) {
          this.show();
          setTimeout(() => {
            if (e.detail.openCartMini) {
              this.open();
            }
          }, this.transitionDuration * 2);
        } else {
          if (e.detail.openCartMini) {
            this.open();
          }
        }
      }
    },
    unbindEvents() {
      this.ui.toggle.removeEventListener('click', this.toggle);
      this.ui.close.removeEventListener('click', this.close);
      document.removeEventListener('cart:updated', this.cartUpdatedHandler);
      document.removeEventListener('page:beforeEnter', this.pageBeforeEnterHandler);
    }
  },
  {
    init() {
      this.firstTimeAdded = true;
      this.visibleKlass = 'is-visible';
      this.openKlass = 'is-open';
      this.justAddedKlass = 'is-just-added';
      this.transitionDuration = 300;
      this.closeAfterDuration = 3000;
      this.isOpen = false;
      this.alreadyInit = false;

      this.cart = {};
      this.latestProductAdded = null;

      this.bindUI();
      this.bindEvents();
      this.getLocalStorage();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default cartMiniBehavior;
