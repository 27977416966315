import createBehavior from '@js/functions/createBehavior.js';

const panelBehavior = createBehavior(
  'panel',
  {
    bindUI() {
      this.btns = this.getChildren('btn');
      this.content = this.getChild('content');
      this.panels = this.getChildren('panel');
    },
    bindEvents() {
      Array.from(this.btns).map(btn => {
        btn.addEventListener('click', this.updateIndex, false);
      });
      document.addEventListener('panel:updated', this.updatePanelHeight, false);
      window.addEventListener('resized', this.resizedHandler, false);
    },
    unbindEvents() {
      Array.from(this.btns).map(btn => {
        btn.removeEventListener('click', this.updateIndex);
      });
      document.removeEventListener('panel:updated', this.updatePanelHeight);
      window.removeEventListener('resized', this.resizedHandler);
    },
    updatePanelHeight() {
      if (this.content) {
        this.content.style.height = this.panels[this.index].offsetHeight + 'px';
      }
    },
    updateIndex(e) {
      // Hide current panel.
      this.panels[this.index].classList.remove(this.activeClass);

      // Get index from button.
      this.index = e.currentTarget.getAttribute('data-index') * 1;

      // Update tab height.
      this.updatePanelHeight();

      // Update btns.
      this.updateBtns();

      // After transition is done, update panels.
      setTimeout(() => {
        this.updatePanels();
      }, this.transitionDuration);
    },
    updateBtns() {
      // Update btns.
      if (this.panels && this.panels.length) {
        Array.from(this.panels).map((panel, key) => {
          if (this.index === key) {
            this.btns[key].classList.add(this.activeClass);
          } else {
            this.btns[key].classList.remove(this.activeClass);
          }
        });
      }
    },
    updatePanels() {
      // Show current panel.
      if (this.panels && this.panels.length) {
        this.panels[this.index].classList.add(this.activeClass);
      }

      // Update accessibility.
      this.updateAccessibility();
    },
    updateAccessibility() {
      if (this.panels && this.panels.length) {
        Array.from(this.panels).map((panel, key) => {
          if (this.index === key) {

            // Update panel.
            panel.setAttribute('aria-hidden', false);
            panel.removeAttribute('tabindex');

            // Update btn.
            if (this.btns && this.btns[key]) {
              this.btns[key].setAttribute('aria-current', true);
            }

          } else {

            // Update panel.
            panel.setAttribute('aria-hidden', true);
            panel.setAttribute('tabindex', '-1');

            // Update btn.
            if (this.btns && this.btns[key]) {
              this.btns[key].setAttribute('aria-current', false);
            }
          }
        });
      }
    },
    resizedHandler() {
      this.updatePanelHeight();
    }
  },
  {
    init() {
      this.index = 0;
      this.transitionDuration = 250;
      this.activeClass = 'is-active';

      this.bindUI();
      this.bindEvents();
      this.updatePanelHeight();
      this.updatePanels();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default panelBehavior;
