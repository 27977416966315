export default function(url) {
  return new Promise(function(resolve, reject) {
    var element = document.createElement('script');
    var parent = 'body';
    var attr = 'src';

    // Important success and error for the promise
    element.onload = function() {
      resolve(url);
    };
    element.onerror = function() {
      reject(url);
    };

    // Enable async loading.
    element.async = true;

    // Inject into document to kick off loading
    element[attr] = url;
    document[parent].appendChild(element);
  });
}
