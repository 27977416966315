import createBehavior from '@js/functions/createBehavior.js';
import Flickity from 'flickity';

const carouselBehavior = createBehavior(
  'carousel',
  {
    bindUI() {
      this.list = this.getChild('list');
      this.items = this.getChildren('item');
      this.btns = this.getChildren('btn');
    },
    initFlickity() {

      let hasControlsHidden = ( this.node.dataset && this.node.dataset.carouselCheckControls && this.items.length <= 4 )

      const self = this;

      this.flky = new Flickity(this.list, {
        cellAlign: this.list.dataset.carouselAlign,
        watchCSS: !!this.list.dataset.carouselMobileOnly || hasControlsHidden,
        contain: true,
        wrapAround: true,
        imagesLoaded: true,
        on: {
          ready: () => {
            setTimeout(() => {
              self.makeOnlyVisibleCellsFocusable();
            }, 100);
          },
          change: () => {
            document.dispatchEvent(new CustomEvent('carousel:change'));
            self.makeOnlyVisibleCellsFocusable();
          },
        }
      });

      // Trick to prevent click on drag
      this.flky.on('dragStart', () => this.flky.slider.style.pointerEvents = 'none' );
      this.flky.on('dragEnd', () => this.flky.slider.style.pointerEvents = 'all' );

      // If check_controls valid hide controls
      if ( hasControlsHidden ) {
        this.node.classList.add('has-controls-hidden');
      }
    },
    makeOnlyVisibleCellsFocusable(){
      var focusableElemsSelector = 'a, button, [tabindex="0"]';
      this.flky.slider.querySelectorAll('[data-carousel-item]').forEach((elem) => {
        elem.querySelectorAll(focusableElemsSelector).forEach((focusableElement) => {
          focusableElement.setAttribute('tabindex', '-1');
        })
      });
      var flickitySelElems = this.flky.selectedElements;
      flickitySelElems.forEach((elem) => {
        elem.querySelectorAll(focusableElemsSelector).forEach((focusableElement) => {
          focusableElement.removeAttribute('tabindex');
        })
      });
    },
    bindEvents() {
      Array.from(this.btns).map(btn => {
        btn.addEventListener('click', this.clickHandler, false);
      })

      if ( this.node.dataset ) {

        if ( this.node.dataset.carouselControlClick ) {
          Array.from(this.items).map(item => {
            item.addEventListener('click', this.clickItemHandler, false);
          })
        }

        if ( this.node.dataset.carouselForceResize ) {
          setTimeout( window.addEventListener('load', this.forceResize), 500 );
          setTimeout( window.addEventListener('load', this.forceResize), 1500 );
        }
      }

      document.addEventListener('card-video:selected', this.onCardVideoSelected);
    },
    onCardVideoSelected(e) {
      this.flky.next( true );
    },
    clickHandler(e) {
      // Get direction.
      const direction = e.currentTarget.getAttribute('data-direction');

      // Based on direction.
      if (direction === 'prev') {
        this.flky.previous();
      } else if (direction === 'next') {
        this.flky.next();
      }
    },
    clickItemHandler(e) {

      if ( e.currentTarget.classList.contains('is-selected') ) return;

      e.preventDefault();
      e.stopPropagation();

      this.flky.select(e.currentTarget.dataset.id);
    },
    forceResize() {
      window.dispatchEvent(new Event('resize'));
    },
    unbindEvents() {
      Array.from(this.btns).map(btn => {
        btn.removeEventListener('click', this.clickHandler);
      })

      if ( this.node.dataset ) {

        if ( this.node.dataset.carouselControlClick ) {
          Array.from(this.items).map(item => {
            item.removeEventListener('click', this.clickItemHandler);
          })
        }

        if ( this.node.dataset.carouselForceResize ) {
          window.removeEventListener('load', this.forceResize);
        }
      }
    }
  },
  {
    init() {
      this.flky = null;

      this.bindUI();
      this.initFlickity();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default carouselBehavior;
