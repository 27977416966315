import createBehavior from '@js/functions/createBehavior.js';

const portraitBehavior = createBehavior(
  'portrait',
  {
    bindUI() {
      this.more = this.getChild('more');
    },
    bindEvents() {
      if (this.more) {
        this.more.addEventListener('click', this.clickHandler, false);
      }
    },
    clickHandler() {
      this.togglePortrait();
    },
    togglePortrait() {
      // Update isOpen variable.
      this.isOpen = !this.isOpen;

      // Toggle class.
      this.node.classList.toggle(this.activeClass);

      // Emit event to update panel height.
      document.dispatchEvent(new CustomEvent('panel:updated'));
    },
    unbindEvents() {
      if (this.more) {
        this.more.removeEventListener('click', this.clickHandler);
      }
    }
  },
  {
    init() {
      this.activeClass = 'is-active';
      this.isOpen = false;

      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default portraitBehavior;
