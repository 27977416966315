import createBehavior from '@js/functions/createBehavior.js';

const accordionBehavior = createBehavior(
  'accordion',
  {
    bindUI() {
      this.btn = this.getChild('btn');
      this.content = this.getChild('content');
      this.link = this.content.querySelector('a');
      this.wrapper = this.getChild('wrapper');
    },
    bindEvents() {
      this.btn.addEventListener('click', this.onClickHandler, false);
      document.addEventListener('accordion:open', this.openHandler, false);
      window.addEventListener('resize', this.resizeHandler, false);
    },
    unbindEvents() {
      this.btn.removeEventListener('click', this.onClickHandler);
      document.removeEventListener('accordion:open', this.openHandler);
      window.removeEventListener('resize', this.resizeHandler);
    },
    onClickHandler() {
      // Update isOpen variable.
      this.isOpen = !this.isOpen;

      // Toggle accordion.
      this.node.classList.toggle(this.openClass);

      // Update accordion.
      this.updateAccordion();

      // Emit event (to close all others accordion on the page).
      if (this.isOpen) {
        document.dispatchEvent(new CustomEvent('accordion:open', {
          detail: {
            el: this.node,
            isOpen: this.isOpen
          }
        }));
      }
    },
    resizeHandler() {
      this.updateAccordion();
    },
    updateAccordion() {
      // Set height on content.
      if (this.isOpen) {
        this.content.style.height = this.wrapper.offsetHeight + 'px';
      } else {
        this.content.style.height = '0px';
      }

      // Update accessibility.
      this.updateAccessibility();
    },
    updateAccessibility() {
      if (this.isOpen) {
        this.btn.setAttribute('aria-expanded', true);
        this.content.setAttribute('aria-hidden', false);
        this.content.removeAttribute('tabindex');
        if (this.link) {
          this.link.removeAttribute('tabindex');
        }
      } else {
        this.btn.setAttribute('aria-expanded', false);
        this.content.setAttribute('aria-hidden', true);
        this.content.setAttribute('tabindex', '-1');
        if (this.link) {
          this.link.setAttribute('tabindex', '-1');
        }
      }
    },
    openHandler(e) {
      if (e.detail.el !== this.node && this.isOpen) {
        this.isOpen = false;
        this.node.classList.remove(this.openClass);
        this.updateAccordion();
      }
    }
  },
  {
    init() {
      this.openClass = 'is-open';
      this.isOpen = this.node.classList.contains(this.openClass);

      this.bindUI();
      this.bindEvents();
      this.updateAccordion();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default accordionBehavior;
