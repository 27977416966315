/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';

const scrollSnapBehavior = createBehavior(
  'scroll-snap',
  {
    bindUI() {
      this.ui = {}

      this.ui.container = this.getChild('carousel');
      this.ui.carousel = this.node.querySelector('.carousel__list');
      this.ui.carouselElements = this.node.querySelectorAll('.carousel__item');
    },
    initObserver() {

      let self = this;
      this.currentIndex = 0;

      var observer = new IntersectionObserver( function(entries, observer) {
        // find the entry with the largest intersection ratio
        var activated = entries.reduce(function (max, entry) {
          return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
        });
        if (activated.intersectionRatio > 0) {
          self.currentIndex = elementIndices[activated.target.dataset.id];
          self.ui.container.dataset.index = self.currentIndex;
        }
      }, {
        root: this.ui.carousel,
        threshold:0.5
      });

      var elementIndices = {};

      for (var i = 0; i < this.ui.carouselElements.length; i++) {
        elementIndices[this.ui.carouselElements[i].dataset.id] = i;
        observer.observe(this.ui.carouselElements[i]);
      }
    }
  },
  {
    init() {
      this.bindUI()
      this.initObserver()
    },
    destroy() {},
  },
);

export default scrollSnapBehavior;
