import createBehavior from '@js/functions/createBehavior.js';
import loadGoogleMapsApi from 'load-google-maps-api';
import getCurrentMediaQuery from '@area17/a17-helpers/src/utility/getCurrentMediaQuery.js';

const directionBehavior = createBehavior(
  'direction',
  {
    loadGoogleMapApi() {
      loadGoogleMapsApi({
        key: this.options.key
      }).then((googleMaps) => {
        this.api = googleMaps;
        this.buildMap();
      }).catch(function (error) {
        console.error(error)
      });
    },
    bindEvents() {
      window.addEventListener('resized', this.resizedHandler, false);
    },
    buildMap() {
      this.map = new this.api.Map(this.getChild('map'), {
        center: {
          lat: 48.876727,
          lng: 2.263879
        },
        zoom: 14,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                  { visibility: 'off' }
            ]
          }
        ]
      });

      // Add custom markers.
      this.addMarkers();

      // Update zoom.
      this.updateZoom();
    },
    addMarkers() {
      // Init icons.
      const icons = {
        fondation: {
          icon: this.options['marker-fondation']
        },
        shuttle: {
          icon: this.options['marker-shuttle']
        }
      };

      // Create markers.
      const markers =   [
        {
          position: new this.api.LatLng(48.8766484, 2.2613367),
          type: 'fondation'
        },
        {
          position: new this.api.LatLng(48.873932, 2.2956028),
          type: 'shuttle'
        },
      ]

      // Add all markers to the map.
      for (let i = 0, j = markers.length; i < j; i++) {
        const marker = new this.api.Marker({
          position: markers[i].position,
          icon: icons[markers[i].type].icon,
          map: this.map
        })
      }
    },
    resizedHandler() {
      // Get breakpoint.
      this.breakpoint = getCurrentMediaQuery();

      // Update zoom.
      this.updateZoom();
    },
    updateZoom() {
      // Based on breakpoint, adjust zoom.
      if (this.map) {
        if (this.breakpoint === 'small') {
          this.map.setZoom(12)
        } else if (this.breakpoint === 'medium') {
          this.map.setZoom(13);
        } else {
          this.map.setZoom(14);
        }
      }
    }
  },
  {
    init() {
      this.api = null;
      this.map = null;
      this.breakpoint = getCurrentMediaQuery();

      this.loadGoogleMapApi();
      this.bindEvents();
    },
    destroy() {
      window.removeEventListener('resized', this.resizedHandler);
    }
  }
);

export default directionBehavior;
