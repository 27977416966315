import getOffset from '@area17/a17-helpers/src/utility/getOffset';
import barba from '@barba/core';
import resetUI from '@js/functions/barbajs/resetUI';
import { setHTMLClassStatus, setHTMLClassNext, setHTMLClassDone } from '@js/functions/barbajs/setBarbajsClass';
import doneTransition from '@js/functions/barbajs/doneTransition';

export function barbajs() {

  // States
  let isPopstate = false;
  let isPanel = false;
  let scrollTop = false;
  let previousPage = '';
  const animTiming = 350;

  function backNavigation() {
    isPopstate = true;
  }

  // Default transition
  const defaultTransition = {
    name: 'default',
    beforeLeave(data) {
      scrollTop = false;
      isPopstate = false;
      isPanel = false;
      previousPage = data.current.url.href;

      resetUI();

      // Fade out current page
      setHTMLClassStatus('before-leave');
      const done = this.async();
      setTimeout(() => {
        done();
      }, animTiming);
    },
    leave(data) {
      document.dispatchEvent(new CustomEvent('page:leave', {
        detail: {
          current: data.current,
          next: data.next
        }
      }));

      setHTMLClassStatus('leaving');
    },
    beforeEnter(data) {
      setHTMLClassStatus('before-enter');

      document.dispatchEvent(new CustomEvent('page:beforeEnter', {
        detail: {
          current: data.current,
          next: data.next
        }
      }));

      // next page classes (for animation purpose)
      if (Object.prototype.hasOwnProperty.call(data, 'next')) {
        setHTMLClassNext(data.next.namespace);
      }
    },
    enter(data) {
      setHTMLClassStatus('entering');
    },
    afterEnter(data) {
      const done = this.async();
      setHTMLClassStatus('after-enter');

      // because we used the back / forward browser button we will scroll back to where we arrived previously (to mimic the default browser behavior)
      window.scrollTo(0, 0);

      // load next page
      done();

      if (data.hasOwnProperty('next')) {
        const fullNamespace = isPanel ? data.next.namespace + ' panel' : data.next.namespace;
        setHTMLClassDone(fullNamespace);
      }

      // make sure the navigation is closed when opening a new page
      document.dispatchEvent(new CustomEvent('nav:hide'));

      // notify behaviors that the content has been replaced and is ready
      setTimeout(() => {
        // There is an anchor in the url so this mean we need to scroll to this point
        if (window.location.hash.replace('#', '')) {
          let target = document.getElementById(window.location.hash.replace('#', ''));
          if (target) {
            let offsetTarget = Math.max(0, getOffset(target).top - 60 + 4);
            window.scrollTo(0, offsetTarget);
          }
        }

        doneTransition(data);
      }, 10);
    }
  };

  // Barbajs Init hook
  // Set a timeout of 15s to make sure heavy pages have time to be loaded through barbajs
  barba.init({
    transitions: [
      defaultTransition
    ],
    prefetchIgnore: true,
    timeout: 15000,
    prevent({ el, ...args }) {
      return false
    }
  });
}

export default barbajs;
