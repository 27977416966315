import createBehavior from '@js/functions/createBehavior.js';
import ListProduct from '@components/list/product/list-product.twig';

const cartPageBehavior = createBehavior(
  'cart-page',
  {
    bindUI() {
      this.ui = {};

      this.ui.list = this.getChild('list');
      this.ui.total = this.getChild('total');
      this.ui.aside = this.getChild('aside');
      this.ui.empty = this.getChild('empty');
    },
    bindEvents() {
      // Add event listener when cart is updated.
      document.addEventListener('cart:updated', this.cartUpdatedHandler, false);
    },
    getLocalStorage() {
      // Get cart from local storage.
      const cartFromLocalStorage = localStorage.getItem('cart');

      // If we don't have any local storage, show empty state.
      if (!cartFromLocalStorage) {
        this.turnPageIntoEmptyState();
      } else {

        // Get cart object.
        const cartObj = JSON.parse(cartFromLocalStorage);

        // If we have a validity and it's not expired.
        if (cartObj.validity && cartObj.validity >= Date.now() && cartObj.locale && cartObj.locale === document.documentElement.getAttribute('lang') && cartObj.products && cartObj.products.length) {

          // Save cart object.
          this.cart = cartObj;

          // Build page.
          this.buildPage();

        } else {

          // Turn into empty page.
          this.turnPageIntoEmptyState();
        }
      }

      // Show page.
      this.node.classList.add('is-visible');
    },
    buildPage() {
      this.node.classList.remove('is-empty');
      this.buildList();
      this.buildAside();
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('page:updated'));
      }, 20);
    },
    buildList() {
      // Loop through each product and build CardProduct.
      const listMarkup = ListProduct({
        items: this.cart.products,
        button_edit_label: this.options['button-edit-label'],
        button_remove_label: this.options['button-remove-label'],
        locale: this.options['locale'],
        variation: 'featured'
      });

      // Append listMarkup inside list.
      this.ui.list.innerHTML = listMarkup;
    },
    buildAside() {
      // Init total variable.
      let total = 0;

      // Calculate total.
      this.cart.products.forEach(product => {
        product.prices.forEach(price => {
          total += price.price * price.quantity
        });
      });

      // Append total.
      this.ui.total.textContent = total;
    },
    turnPageIntoEmptyState() {
      this.node.classList.add('is-empty');
      this.ui.list.innerHTML = '';
    },
    cartUpdatedHandler(e) {
      // Update cart.
      this.cart = e.detail ? e.detail.cart : null;

      // If we have some products.
      if (this.cart && this.cart.products && this.cart.products.length) {
        this.buildPage();
      } else {
        this.turnPageIntoEmptyState();
      }
    },
    unbindEvents() {
      // Remove event listener when cart is updated.
      document.removeEventListener('cart:updated', this.cartUpdatedHandler);
    }
  },
  {
    init() {
      // Save cart.
      this.cart = null;

      this.bindUI();
      this.bindEvents();
      this.getLocalStorage();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default cartPageBehavior;
