/**
 * @prettier
 */

import lockScroll from '@js/utils/lock-scroll.js';
import createFocusTrap from 'focus-trap';
import createBehavior from '@js/functions/createBehavior.js';
import ax from '@js/functions/axios.js';
import qs from 'qs';
import { debounce } from 'lodash';
import SearchDialogResults from './search-dialog-results.twig';
import Fuse from 'fuse.js';
import highlightWords from 'highlight-words';

const ESC_KEY = 'Escape';
const ARROW_UP_KEY = 'ArrowUp';
const ARROW_DOWN_KEY = 'ArrowDown';

let __searchCache = {};

/**
 * A simple cache, where identical URLs in-memory will be reused.
 *
 * For example, if the user goes to the artist page on full page load, the cache will be created and used
 * but if they return during the same page load (via Barba), then the previous cached version will be used
 *
 * @param {string} url - the URL endpoint for Axios, and the key for the cache
 */
async function getSearchCache(url, keys) {
  if (url in __searchCache) {
    return __searchCache[url];
  }
  let json = await ax.get(url);
  let fuse = new Fuse(json.data, {
    includeScore: true,
    includeMatches: true,
    keys,
  });
  __searchCache[url] = fuse;
  return fuse;
}

const searchDialogBehavior = createBehavior(
  'search-dialog',
  {
    bindUI() {
      this.ui = {};
      this.ui.inner = this.getChild('inner');
      this.ui.input = this.getChild('input');
      this.ui.form = this.getChild('form');
      this.ui.results = this.getChild('results');
      this.ui.btn = this.getChild('btn');
      this.ui.closeBtn = this.getChild('close-btn');
    },
    bindEvents() {
      this.node.addEventListener('click', this.handleClick);
      this.ui.inner.addEventListener('click', this.handleInnerClick);
      this.ui.input.addEventListener('input', this.handleInputInput);
      this.afterInputInput = debounce(this.handleAfterInputInput, 0);
      this.ui.input.addEventListener('input', this.afterInputInput);
      document.addEventListener('keydown', this.handleDocumentKeydown);
      document.addEventListener(
        'search-dialog:request-open',
        this.handleSearchDialogRequested,
      );
      this.ui.form.addEventListener('click', this.handleFormClick);
      this.ui.results.addEventListener('click', this.handleResultsClick);
      this.ui.closeBtn.addEventListener('click', this.handleCloseBtnClick);

      // Event delegation using focusin/focusout (bubble supported)
      this.ui.results.addEventListener('focusin', this.handleResultsFocusin);
      this.ui.results.addEventListener('focusout', this.handleResultsFocusout);

      this.ui.form.addEventListener('submit', this.handleFormSubmit);
    },
    cacheDimensions() {},
    updateUI() {
      let { value } = this.ui.input;
      this.ui.results.classList.toggle('is-visible', value && this.resultsHTML);
      this.ui.results.innerHTML = this.resultsHTML;
      // this.node.classList.toggle('is-loading', this.isLoading);
      // this.ui.btn.disabled = this.isLoading;
    },
    unbindEvents() {
      document.removeEventListener('keydown', this.handleDocumentKeydown);
      this.node.removeEventListener('click', this.handleClick);
      this.ui.inner.removeEventListener('click', this.handleInnerClick);
      this.ui.input.removeEventListener('input', this.handleInputInput);
      this.ui.input.removeEventListener('input', this.afterInputInput);
      document.removeEventListener(
        'search-dialog:request-open',
        this.handleSearchDialogRequested,
      );
    },
    handleDocumentKeydown(evt) {
      switch (evt.key) {
        case ESC_KEY:
          this.close();
          break;
        case ARROW_UP_KEY:
          this.traverseResults(-1);
          document.body.classList.add('is-tabbed');
          break;
        case ARROW_DOWN_KEY:
          document.body.classList.add('is-tabbed');
          if (this.node.classList.contains('has-focused-result')) {
            this.traverseResults(1);
          } else {
            this.focusFirstResult();
          }
          break;
      }
    },
    handleResultsFocusin(evt) {
      let resultLinks = Array.from(this.ui.results.querySelectorAll('a'));
      if (resultLinks.indexOf(evt.target) > -1) {
        this.node.classList.add('has-focused-result');
      }
    },
    handleResultsFocusout(evt) {
      let resultLinks = Array.from(this.ui.results.querySelectorAll('a'));
      if (resultLinks.indexOf(evt.target) > -1) {
        this.node.classList.remove('has-focused-result');
      }
    },
    handleFormSubmit() {
      if (
        !this.node.classList.add('has-focused-result') &&
        this.ui.input.value.trim()
      ) {
        let resultLinks = Array.from(this.ui.results.querySelectorAll('a'));
        if (resultLinks.length) {
          let firstLink = resultLinks[0];
          firstLink.click();
        }
      }
    },
    traverseResults(dir = 1) {
      if (this.node.classList.contains('is-visible')) {
        let resultItems = Array.from(this.ui.results.querySelectorAll('li'));
        let activeEl = document.activeElement;
        if (activeEl.nodeName === 'A') {
          let closestLi = activeEl.closest('li');
          if (closestLi) {
            let idx = resultItems.indexOf(closestLi);
            resultItems[idx + dir]?.querySelector('a').focus();
          }
        }
      }
    },
    focusFirstResult() {
      let resultLinks = Array.from(this.ui.results.querySelectorAll('a'));
      if (resultLinks.length) {
        let firstLink = resultLinks[0];
        firstLink.focus();
      }
    },
    handleInputInput(evt) {
      let { value } = this.ui.input;
      let trimmedValue = value.trim();
      if (!trimmedValue) {
        this.resultsHTML = '';
      }
      this.updateUI();
    },
    handleAfterInputInput() {
      let { value } = this.ui.input;
      let trimmedValue = value.trim();
      if (trimmedValue) {
        this.fetchResults(trimmedValue);
        this.updateUI();
      }
    },
    open() {
      if (!this.node.classList.contains('is-visible')) {
        this.node.classList.add('is-visible');
        lockScroll.enable(this.node);
        this.focusTrap.activate();
        this.ui.input.focus();
      }
    },
    close() {
      if (this.node.classList.contains('is-visible')) {
        this.node.classList.remove('is-visible');
        lockScroll.disable(this.node);
        this.focusTrap.deactivate();
      }
    },
    handleClick() {
      this.close();
    },
    handleFormClick(evt) {
      evt.stopPropagation();
    },
    handleResultsClick(evt) {
      evt.stopPropagation();
    },
    handleCloseBtnClick(evt) {
      evt.preventDefault();
      this.close();
    },
    handleSearchDialogRequested(evt) {
      if (evt.detail.dialog.id === this.options.id) {
        this.open();
      }
    },
    async fetchResults(q) {
      let results = this.fuse.search(q);
      let twigResults = results.map((v) => ({
        ...v.item,
        text: highlightWords({
          text: v.item.label || v.item.name,
          query: q,
        }),
      }));

      this.resultsHTML = SearchDialogResults({
        results: twigResults,
        term: q,
      });
      this.updateUI();
    },
    async getSearchCache() {
      return await getSearchCache(
        this.options.url,
        JSON.parse(this.options.keys),
      );
    },
  },
  {
    async init() {
      this.focusTrap = createFocusTrap(this.node);
      this.resultsHTML = '';
      this.bindUI();

      this.cacheDimensions();
      this.updateUI();
      this.fuse = await this.getSearchCache();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default searchDialogBehavior;
