import createBehavior from '@js/functions/createBehavior.js';

const customFocusBehavior = createBehavior(
  'custom-focus',
  {
    bindUI() {
      this.target = this.getChild('target');
    },
    bindEvent() {
      if (this.target) {
        this.target.addEventListener('focus', this.focusHandler, false);
        this.target.addEventListener('blur', this.blurHandler, false);
        this.target.addEventListener('change', this.onChangeHandler, false);
      }
    },
    focusHandler() {
      if (document.body.classList.contains('is-tabbed')) {
        this.node.classList.add(this.isFocusClass);
      }
    },
    blurHandler() {
      this.node.classList.remove(this.isFocusClass);
    },
    onChangeHandler() {
      if ( this.target.tagName.toLowerCase() == 'select' ) {
        this.target.dataset.selected = this.target.value;
      }
    },
    unbindEvents() {
      if (this.target) {
        this.target.removeEventListener('focus', this.focusHandler);
        this.target.removeEventListener('blur', this.blurHandler);
        this.target.removeEventListener('change', this.onChangeHandler);
      }
    }
  },
  {
    init() {
      this.isFocusClass = 'is-focus';

      this.bindUI();
      this.bindEvent();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default customFocusBehavior;
