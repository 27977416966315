import createBehavior from '@js/functions/createBehavior.js';

const affluenceBehavior = createBehavior(
  'affluence',
  {
    toggleAffluence() {
      this.regular.style = this.toggle.checked ? "display:none;" : "display:table-row-group;"
      this.holiday.style = this.toggle.checked ? "display:table-row-group;" : "display: none;"
    }
  },
  {
    init() {
      this.regular = this.getChild('regular');
      this.holiday = this.getChild('holiday');
      this.toggle = document.querySelector('input[id="toggleAffluenceSchedule"]');

      this.toggle.addEventListener('change', this.toggleAffluence)
    },
    destroy() {
      this.toggle.removeEventListener('change', this.toggleAffluence)
    }
  }
);

export default affluenceBehavior;
