import createBehavior from '@js/functions/createBehavior.js';
import SC from '@js/functions/soundcloud.api.js';

const soundCoverBehavior = createBehavior(
  'sound-cover',
  {
    bindUI() {
      this.playerContainer = this.getChild('player-container');
      this.iframe = this.playerContainer.querySelector('iframe');
      this.player = this.iframe ? SC.Widget(this.iframe) : null;
      this.duration = this.getChild('duration');
      this.btnPlay = this.getChild('btn-play');
      this.labelTracks = document.querySelector('[data-sound-cover-label-tracks]').dataset.soundCoverLabelTracks || 'tracks';
    },
    bindEvents() {
      if (this.player) this.player.bind(SC.Widget.Events.READY, () => {
        this.populateDuration();
      });
      this.btnPlay.addEventListener('click', this.clickHandler, false);
    },
    populateDuration() {

      this.player.getSounds((tracks) => {
        if ( tracks.length > 1 ) {
          this.duration.textContent = tracks.length + ' ' + this.labelTracks;
        } else {
          this.player.getDuration( (ms) => {
            this.duration.textContent = this.msToMinSec(ms);
          } );
        }
      });
    },
    clickHandler(e) {
      e.preventDefault();
      if (this.player) {
        this.node.classList.add('show-player');
        this.player.play();
      }
    },
    unbindEvents() {
      this.btnPlay.removeEventListener('click', this.clickHandler);
      if (this.player) this.player.unbind(SC.Widget.Events.READY);
    },
    msToMinSec(ms, showSec) {
      var min = Math.floor(ms / 60000);
      var sec = ((ms % 60000) / 1000).toFixed(0);
      return min + (showSec ? (":" + (sec < 10 ? '0' : '') + sec) : ' min');
    }
  },
  {
    init() {

      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default soundCoverBehavior;
