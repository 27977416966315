/**
 * @prettier
 */

 import createBehavior from '@js/functions/createBehavior.js';
 import ax from '@js/functions/axios.js';

 const REFRESH_RATE_IN_MINUTES = 10;

 const loadMoreBehavior = createBehavior(
   'opening-hours',
   {
     bindUI() {
       this.ui = {}
       this.ui.isOpen = this.getChildren('is-open');
       this.ui.text = this.getChildren('text');
       this.locale = this.node.getAttribute('data-cart-locale') ?? 'en';
       this.isOpen = false;
       this.lastRefresh = 0;
       this.text = null;
     },
     bindEvents() {
        document.addEventListener(
           'page:updated',
           this.handleRefresh,
        );
     },
     unbindEvents() {
        document.removeEventListener(
            'page:updated',
            this.handleRefresh,
        );
     },
     handleRefresh() {
        this.ui.isOpen = this.getChildren('is-open');
        this.ui.text = this.getChildren('text');
        this.shouldRefresh() && this.fetchOpeningHours();
        this.isOpen && this.updateUI();
     },
     updateUI() {
        this.ui.isOpen.forEach((node) => {
            node.classList.add(node.getAttribute('data-opening-hours-is-open'));
        })

        this.ui.text.forEach((node) => {
            node.innerText = this.text;
        })
     },
     shouldRefresh() {
        let seconds = 1000;
        let minutes = 60 * seconds;
        let diff = Date.now() - this.lastRefresh;
        let elapsedTimeInMinutes = diff / minutes;

        // Check if should refresh
        return elapsedTimeInMinutes >= REFRESH_RATE_IN_MINUTES
     },
     fetchOpeningHours() {
        this.node.classList.add('is-loading-opening-hours');
        ax({
          method: 'GET',
          url: `/api/v1/open-today?locale=${this.locale}`,
        })
          .then((res) => {
            this.node.classList.remove('is-loading-opening-hours');
            this.isOpen = res.data.isOpen;
            this.lastRefresh = Date.now();
            this.text = res.data.openingText;
            this.updateUI();
          })
          .catch((err) => {
            this.node.classList.remove('is-loading-opening-hours');
            this.node.classList.add('is-availability-error');
          });
      }
   },
   {
     init() {
       this.bindUI()
       this.bindEvents()
       this.fetchOpeningHours()
     },
     destroy() {
        this.unbindEvents()
     },
   },
 );

 export default loadMoreBehavior;
