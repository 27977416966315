var doneTransition = function(data) {
  document.dispatchEvent(new CustomEvent('page:updated', {
    detail: {
      current: data.current,
      next: data.next
    }
  }));
};

export default doneTransition;
