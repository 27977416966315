import createBehavior from '@js/functions/createBehavior.js';

const formBehavior = createBehavior(
  'form',
  {
    bindUI() {
      this.recaptcha = this.node.querySelector('#a-recaptcha');
    },
    bindEvents() {
      if (this.recaptcha) {
        document.addEventListener('captcha:loaded', this.renderCaptcha);
      }
      document.addEventListener('submit', this.formSubmit);
    },
    renderCaptcha() {
      if (window.A17.CAPTCHA_LOADED && this.recaptcha) {
        window.grecaptcha.render(this.recaptcha.getAttribute('id'), {
          'sitekey' : this.recaptcha.getAttribute('data-sitekey')
        });
      }
    },
    formSubmit() {
      let submitButton = this.node.querySelector("button[type='submit']")
      if(submitButton){
        submitButton.setAttribute('disabled', true)
      }
    },
    unbindEvents() {
      if (this.recaptcha) {
        document.removeEventListener('captcha:loaded', this.renderCaptcha);
      }
    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default formBehavior;
