import createBehavior from '@js/functions/createBehavior.js';
import dataLayerPush from '@js/functions/dataLayerPush.js';

const dataLayerPushBehavior = createBehavior(
  'dataLayerPush',
  {
  },
  {
    init() {
      if (window.dataLayer && this.options.id) {
        const dataTag = document.getElementById(this.options.id);
        if (dataTag) {
          dataLayerPush(JSON.parse(dataTag.innerHTML));
        }
      }
    }
  }
);

export default dataLayerPushBehavior;
