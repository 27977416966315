import createBehavior from '@js/functions/createBehavior.js';
import Flickity from 'flickity';

const carouselVideoBehavior = createBehavior(
  'carousel-video',
  {
    bindUI() {
      this.list = this.getChild('list');
      this.items = this.getChildren('item');
      this.btns = this.getChildren('btn');

      this.videoCaption = this.list.querySelector('.video__caption');
      this.videoContent = this.list.querySelector('.video__content');

      this.btnPrev = this.getChild('btn-prev');
      this.btnNext = this.getChild('btn-next');
    },
    initFlickity() {
      this.flky = new Flickity(this.list, {
        cellAlign: 'left',
        contain: true,
        wrapAround: false
      });
      this.maxIndex = this.flky.slides.length - 1;
    },
    bindEvents() {
      Array.from(this.btns).map(btn => {
        btn.addEventListener('click', this.clickHandler, false);
      })

      this.flky.on('change', this.changeHandler);
      window.addEventListener('resized', this.resizedHandler, false);
    },
    clickHandler(e) {
      // Get direction.
      const direction = e.currentTarget.getAttribute('data-direction');

      // Based on direction.
      if (direction === 'prev') {
        this.flky.previous();
      } else if (direction === 'next') {
        this.flky.next();
      }
    },
    changeHandler(index, isFirstLoad) {

      if ( !isFirstLoad ) {
        let
          iframe = this.flky.cells[this.currentIndex].element.querySelector('iframe'),
          iframeSrc = iframe.getAttribute('src');

        iframe.setAttribute("src", iframeSrc);
        this.currentIndex = this.flky.selectedIndex;
      }

      if ( index == 0 ) {
        if (this.btnPrev) this.btnPrev.classList.add('hidden');
        if (this.btnNext) this.btnNext.classList.remove('hidden');
      } else if ( index == this.maxIndex ) {
        if (this.btnPrev) this.btnPrev.classList.remove('hidden');
        if (this.btnNext) this.btnNext.classList.add('hidden');
      } else {
        if (this.btnPrev) this.btnPrev.classList.remove('hidden');
        if (this.btnNext) this.btnNext.classList.remove('hidden');
      }
    },
    resizedHandler() {
      this.positionBtns();
    },
    positionBtns() {
      // if ( !this.videoCaption && !this.videoContent ) return;
      let videoCaptionHeight = ( this.videoCaption && this.videoContent ) ? this.outerHeight(this.list.querySelector('.video__caption')) + this.outerHeight(this.list.querySelector('.video__content')) : 0;

      Array.from(this.btns).map(btn => {



        if ( videoCaptionHeight === 0 ) {
          btn.style.marginTop = '-'+(22+130)+'px';
        } else {
          btn.style.marginTop = '-'+(22+110+(videoCaptionHeight/2))+'px';
        }

      })
    },
    unbindEvents() {
      Array.from(this.btns).map(btn => {
        btn.removeEventListener('click', this.clickHandler);
      });

      this.flky.off('change', this.changeHandler);
      window.removeEventListener('resized', this.resizedHandler);
    },
    outerHeight(element) {
      if ( !element ) {
        return 0;
      }

      const
        height = element ? element.offsetHeight : 0,
        style = window.getComputedStyle(element)

      return ['top', 'bottom']
        .map(side => parseInt(style[`margin-${side}`]))
        .reduce((total, side) => total + side, height)
    }
  },
  {
    init() {
      this.flky = null;

      this.bindUI();
      this.initFlickity();
      this.bindEvents();

      this.currentIndex = 0;
      this.changeHandler(0, true);
      this.positionBtns();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default carouselVideoBehavior;
