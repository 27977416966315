// Global
import '@babel/polyfill';
import barba from '@barba/core';
import barbaCss from '@barba/css';
import { manageBehaviors } from '@js/functions/manageBehaviors.js';
import Polyfills from '@js/polyfills/polyfills.js';
import lazyLoad from '@area17/a17-helpers/src/utility/lazyLoad.js';
import scrolled from '@area17/a17-helpers/src/utility/scrolled.js';
import resized from '@area17/a17-helpers/src/utility/resized.js';
import { barbajs } from '@js/functions/barbajs';
import IntersectionObserverPolyfill from 'intersection-observer';

// Behaviors
import accordionBehavior from '@components/accordion/accordion.js';
import adyenBehavior from '@js/behaviors/adyen.js';
import bannerBehavior from '@components/banner/banner.js';
import carouselBehavior from '@components/carousel/carousel.js';
import carouselVideoBehavior from '@components/carousel-video/carousel-video.js';
import cartBehavior from '@js/behaviors/cart.js';
import cardProductBehavior from '@components/card/product/card-product.js';
import cardVideoBehavior from '@components/card/flv-play-video/card-flv-play-video.js';
import cartMiniBehavior from '@components/cart-mini/cart-mini.js';
import cartPageBehavior from '@js/behaviors/cart-page.js';
import worksGridCascadingBehavior from '@components/works-grid-cascading/works-grid-cascading.js';
import worksGridBehavior from '@components/works-grid/works-grid.js';
import chatbotBehavior from '@components/chatbot/chatbot.js';
import cookieBehavior from '@components/cookie/cookie.js';
import customFocusBehavior from '@js/utils/custom-focus.js';
import datepickerBehavior from '@components/datepicker/datepicker.js';
import datepickerCartBehavior from '@components/datepicker/cart/datepicker-cart.js';
import datepickerHoursBehavior from '@components/datepicker/hours/datepicker-hours.js';
import datepickerRangeBehavior from '@components/datepicker/range/datepicker-range.js';
import datepickerSingleBehavior from '@components/datepicker/single/datepicker-single.js';
import detectTabBehavior from '@js/utils/detect-tab.js';
import directionBehavior from '@components/direction/direction.js';
import dropdownBehavior from '@components/dropdown/dropdown.js';
import eventTriggerBehavior from '@js/utils/event-trigger.js';
import footerBehavior from '@components/footer/footer.js';
import formBehavior from '@components/form/form.js';
import formDateBehavior from '@components/form/date/form-date.js';
import formQuantityBehavior from '@components/form/quantity/form-quantity.js';
import headerBehavior from '@components/header/header.js';
import heroArtworkBehavior from '@components/hero/artwork/hero-artwork.js';
import indexedListBehavior from '@components/indexed-list/indexed-list.js';
import liveBehavior from '@components/live/live.js';
import modalVideoBehavior from '@components/modal/video/modal-video.js';
import navAnchorBehavior from '@components/nav/anchor/nav-anchor.js';
import navTabsBehavior from '@components/nav/tabs/nav-tabs.js';
import notificationBehavior from '@components/notification/notification.js';
import panelBehavior from '@components/panel/panel.js';
import portraitBehavior from '@components/portrait/portrait.js';
import scrollRevealBehavior from '@js/utils/scroll-reveal.js';
import searchDialogBehavior from '@components/search-dialog/search-dialog.js';
import sidebarBehavior from '@components/sidebar/sidebar.js';
import soundCoverBehavior from '@components/sound-cover/sound-cover.js';
import tooltipBehavior from '@components/tooltip/tooltip.js';
import hangsListing from '@components/hangs-listing/hangs-listing.js';
import videoBehavior from '@components/video/video.js';
import productAvailabilityBehavior from '@js/behaviors/product-availability.js';
import openinghours from '@js/behaviors/opening-hours.js';
import loadMore from '@js/behaviors/load-more.js';
import scrollSnap from '@js/behaviors/scroll-snap.js';
import seeMore from '@js/behaviors/see-more.js';
import dataLayerPushBehavior from '@js/behaviors/datalayerpush.js';
import affluenceBehavior from '@components/affluence/affluence.js';
import triggerFilterBehavior from '@components/form/toggle/trigger-filter.js';

// Setup Behaviors object.
const Behaviors = {
  'accordion': accordionBehavior,
  'adyen': adyenBehavior,
  'load-more': loadMore,
  'see-more': seeMore,
  'scroll-snap': scrollSnap,
  'works-grid': worksGridBehavior,
  'banner': bannerBehavior,
  'carousel': carouselBehavior,
  'carousel-video': carouselVideoBehavior,
  'card-product': cardProductBehavior,
  'card-video': cardVideoBehavior,
  'cart': cartBehavior,
  'hangs-listing': hangsListing,
  'cart-mini': cartMiniBehavior,
  'cart-page': cartPageBehavior,
  'chatbot': chatbotBehavior,
  'cookie': cookieBehavior,
  'custom-focus': customFocusBehavior,
  'datepicker': datepickerBehavior,
  'datepicker-cart': datepickerCartBehavior,
  'datepicker-hours': datepickerHoursBehavior,
  'datepicker-range': datepickerRangeBehavior,
  'datepicker-single': datepickerSingleBehavior,
  'detect-tab': detectTabBehavior,
  'direction': directionBehavior,
  'dropdown': dropdownBehavior,
  'event-trigger': eventTriggerBehavior,
  'footer': footerBehavior,
  'form': formBehavior,
  'form-date': formDateBehavior,
  'form-quantity': formQuantityBehavior,
  'header': headerBehavior,
  'hero-artwork': heroArtworkBehavior,
  'indexed-list': indexedListBehavior,
  'live': liveBehavior,
  'modal-video': modalVideoBehavior,
  'nav-anchor': navAnchorBehavior,
  'nav-tabs': navTabsBehavior,
  'notification': notificationBehavior,
  'panel': panelBehavior,
  'portrait': portraitBehavior,
  'scroll-reveal': scrollRevealBehavior,
  'search-dialog': searchDialogBehavior,
  'sidebar': sidebarBehavior,
  'sound-cover': soundCoverBehavior,
  'tooltip': tooltipBehavior,
  'video': videoBehavior,
  'works-grid-cascading': worksGridCascadingBehavior,
  'product-availability': productAvailabilityBehavior,
  'opening-hours': openinghours,
  'dataLayerPush': dataLayerPushBehavior,
  'affluence': affluenceBehavior,
  'trigger-filter': triggerFilterBehavior
};

window.A17 = {};
window.A17.breakpoints = [ 'small', 'medium', 'large', 'xlarge', 'xxlarge' ];
window.A17.latestScrollPosition = 0;


// After DOM is loaded, init JS.
document.addEventListener('DOMContentLoaded', function() {

  // Polyfill for intersection observer.
  IntersectionObserver.prototype.POLL_INTERVAL = 100;

  // Manage behaviors.
  manageBehaviors(Behaviors);

  // Init resized function.
  resized();

  // Init scroll function.
  scrolled();

  // Waiting DOM elements.
  setTimeout(() => {

    // lazyLoad
    lazyLoad();

    // Detect if the site is being loaded into an iframe
    function inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    // Barba JS transitions
    if(!inIframe() && document.querySelector('[data-content]')) {
      barbajs();
    }

  }, 100);

});
