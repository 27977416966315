import createBehavior from '@js/functions/createBehavior.js';
import { createPopper } from '@popperjs/core';

const dropdownBehavior = createBehavior(
  'dropdown',
  {
    bindUI() {
      this.input = this.getChild('input');
      this.button = this.getChild('btn');
      this.panel = this.getChild('panel');
      this.label = this.getChild('label')
    },
    bindEvents() {
      if (this.input) {
        this.input.addEventListener('change', this.onChangeHandler, false);
      }

      if (this.button) {
        this.button.addEventListener('click', this.clickHandler, false);
      }

      if (this.panel) {
        this.panel.addEventListener('click', this.onPanelClickHandler, false);
      }
    },
    unbindEvents() {
      if (this.input) {
        this.input.removeEventListener('change', this.onChangeHandler);
      }

      if (this.button) {
        this.button.removeEventListener('click', this.clickHandler);
      }

      if (this.panel) {
        this.panel.removeEventListener('click', this.onPanelClickHandler, false);
      }

      window.removeEventListener('click', this.clickOutsideHandler, false);
    },
    onChangeHandler(e) {
      window.location.href = e.target.value;
    },
    clickHandler(evt) {
      // Toggle class on node.
      if (this.node.classList.contains('is-open')) {
        this.close();
      } else {
        this.open();
      }
    },
    clickOutsideHandler(e) {
      this.close();
    },
    onPanelClickHandler(evt) {
      // Prevent any panel clicks being treated as an outside click
      evt.stopPropagation();
    },
    open() {
      this.node.classList.add('is-open');
      if (this.panel) this.panel.classList.add('is-open');
      setTimeout(() => {
        // Run on next event loop
        window.addEventListener('click', this.clickOutsideHandler, false);
      }, 0)
    },
    close() {
      this.node.classList.remove('is-open');
      if (this.panel) this.panel.classList.remove('is-open');
      // Immediately remove, to stop the propagation
      window.removeEventListener('click', this.clickOutsideHandler, false);
    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();

      this.options.popper = this.options.popper != null;

      if (this.options.popper) {
        if (!this.panel) {
          // Get the panel by label
          this.panel = this.node.closest('.container').querySelector('#' + this.label.getAttribute('for'));
        }
        this.popper = createPopper(this.button, this.panel, {
          placement: 'bottom',
        })
      }
    },
    destroy() {
      this.unbindEvents();
      if (this.popper) {
        this.popper.destroy();
      }
    }
  }
);

export default dropdownBehavior;
