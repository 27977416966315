import createBehavior from '@js/functions/createBehavior.js';

const scrollRevealBehavior = createBehavior(
  'scroll-reveal',
  {
    bindUI() {
      this.els = this.getChildren('element');
    },
    bindEvents() {
      document.addEventListener('page:updated', this.resetBehavior, false);
    },
    buildObserver(el) {
      // Create options.
      const options = {
        rootMargin: '0px 0px -15% 0px'
      };

      // Create new observer.
      this.observer = new IntersectionObserver(this.updatePage, options);

      // Observe all elements.
      Array.from(this.els).map(el => {
        this.observer.observe(el);
      });
    },
    resetBehavior() {
      this.bindUI();
      this.buildObserver();
      this.bindEvents();
    },
    updatePage(entries, observer) {
      // Add class when we reach the element.
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          this.observer.unobserve(entry.target);
          setTimeout(() => {
            entry.target.classList.add('is-animation-done');
          }, 700);
        }
      });
    },
    disconnectObserver() {
      this.observer.disconnect();
    }
  },
  {
    init() {
      this.observer = null;

      this.bindUI();
      this.buildObserver();
      this.bindEvents();
    },
    destroy() {
      document.removeEventListener('page:updated', this.resetBehavior);
      this.disconnectObserver();
    }
  }
);

export default scrollRevealBehavior;
