/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import scrollToY from '@area17/a17-helpers/src/utility/scrollToY.js';

const navTabsBehavior = createBehavior(
  'nav-tabs',
  {
    bindUI() {
      this.links = this.getChildren('link');
    },
    bindEvents() {
      Array.from(this.links).map((link) => {
        link.addEventListener('click', this.clickHandler, false);
      });
    },
    unbindEvents() {
      Array.from(this.links).map((link) => {
        link.removeEventListener('click', this.clickHandler);
      });
    },
    clickHandler(e) {
      // Prevent default.
      e.preventDefault();

      // Get href.
      const href = e.currentTarget.getAttribute('href');

      // Get target element.
      const targetEl = document.querySelector(href);

      // Toggle target visibility
      Array.from(this.links).forEach((link) => {
        const linkHref = link.getAttribute('href');
        const linkTarget = document.querySelector(linkHref);
        link.classList.toggle('is-active', link === e.currentTarget);
        linkTarget.classList.toggle('is-visible', link === e.currentTarget);
      });

      // If target element exists, scroll to.
      if (targetEl) {
        scrollToY({
          el: document,
          offset: targetEl.getBoundingClientRect().top + window.scrollY - 100,
          duration: 500,
          easing: 'easeInOut',
        });
      }
    },
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default navTabsBehavior;
