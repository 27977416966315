/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import isOutOfViewport from "@js/functions/isOutOfViewport";

const cardVideoBehavior = createBehavior(
  'card-video',
  {
    bindUI() {
      this.ui = {};
      this.ui.link = this.getChild('link');
      this.ui.iframe = this.getChild('iframe');

    },
    bindEvents() {

      this.ui.link.addEventListener('click', this.linkClickHandler, false);

      // Listen to carousel
      document.addEventListener('carousel:change', this.onCarouselChange, false);



    },
    linkClickHandler(e) {
      let item = this.node.closest('.carousel__item')
      let list = item.parentNode


      if (isOutOfViewport(e.currentTarget).right) {
        document.dispatchEvent(new CustomEvent('card-video:selected', {
          "detail": {
            "index" : Array.prototype.indexOf.call(list.children, item),
          }
        }));
      } else {
        document.dispatchEvent(new CustomEvent('carousel:change'));
      }

      let videoData = JSON.parse(this.node.dataset.video);
      let videoSrc;

      if ( videoData.type == "youtube" ) {
        videoSrc = "https://www.youtube.com/embed/" + videoData.video_id + "?autoplay=1&mute=0&loop=" + videoData.loop + "&enablejsapi=true&playlist=" +  videoData.video_id;
      } else if ( videoData.type == "freecaster" ) {
        videoSrc = "//"+videoData.freecasterDomain+"/embed/" + videoData.video_id + "?stretching=cover&autoplay=true&muted=false&loop=" + videoData.loop;
      }

      if ( document.documentElement.clientWidth > 600 ) {
        if ( this.ui.iframe ) this.ui.iframe.src = videoSrc;
        this.node.classList.add('show-video');
      } else {

        document.dispatchEvent(new CustomEvent('card-video:play', { "detail" : { "src" : videoSrc, "ratio": this.node.dataset.ratio } }));
      }

    },
    onCarouselChange() {
      if (this.ui.iframe) this.ui.iframe.src = "";
      this.node.classList.remove('show-video');

    }
  },
  {
    init() {
      this.bindUI()
      this.bindEvents()
    },
    destroy() {},
  },
);

export default cardVideoBehavior;
