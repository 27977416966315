export function dataLayerPush(data) {
  if (window.dataLayer && data) {
    if (Array.isArray(data)) {
      data.forEach(item => {
        if (item.constructor === Object) {
          //console.log('::dataLayerPush (Array)', item);
          window.dataLayer.push(item);
        }
      });
    } else if (data.constructor === Object) {
      //console.log('::dataLayerPush (Object)', data);
      window.dataLayer.push(data);
    }
  }
}

export default dataLayerPush;
