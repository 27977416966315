// Get elements.
const htmlEl    = document.documentElement;
let contentEl = document.querySelector('[data-content]');

// Save class names.
let klassScrollLocked = 'is-scroll-locked';

// Init variables.
let lastScrollPosition = null;
let timer = null;

function enable(duration) {
  // Emit event (do disable behaviors for example)
  window.dispatchEvent(new CustomEvent('lockScroll:willBeEnable'));

  // Lock scroll after.
  timer = setTimeout(() => {
    // If there is a timer, remove it.
    if (timer) {
      clearTimeout(timer);
    }

    // Get content.
    contentEl = document.querySelector('[data-content]');

    // If content element doesn't exist, return.
    if (!contentEl) return;

    // Save last scroll position.
    lastScrollPosition = pageYOffset;

    // If page is already locked.
    if (htmlEl.classList.contains(klassScrollLocked)) {
      lastScrollPosition = contentEl.style.top;
    }

    // Once the panel is open, block scroll.
    htmlEl.classList.add(klassScrollLocked);
    contentEl.style.top = `${-lastScrollPosition}px`;

    // Emit event.
    window.dispatchEvent(new CustomEvent('lockScroll:enable'));
  }, duration ? duration : 350);
}

function disable() {
  // Remove scroll locked class on html.
  htmlEl.classList.remove(klassScrollLocked);

  // If timer exists, clear it.
  if (timer) {
    clearTimeout(timer);
  }

  // If content element doesn't exist, return.
  if (!contentEl) return;

  // If we have a lastScrollPosition value, scroll to this position.
  if (lastScrollPosition) {
    contentEl.style.top = '0px';
    window.scrollTo(0, lastScrollPosition);
    lastScrollPosition = null;
  }

  // Emit event.
  setTimeout(() => {
    window.dispatchEvent(new CustomEvent('lockScroll:disable'));
  }, 20);
}

export default {
  enable,
  disable
}
