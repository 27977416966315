import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';
import { datepickerConfig } from '@js/datepicker/index.js';
import ax from '@js/functions/axios.js';

const datepickerSingleBehavior = createBehavior(
  'datepicker-single',
  {
    checkType() {
      switch(this.options.id) {
        case 'datepicker-cart':
          this.type = 'cart';
          this.initDatepickerCart();
          break;
        default:
          break;
      }
    },
    initDatepickerCart() {
      // Get elements.
      this.sidebar = document.querySelector('#sidebar-cart');
      this.sidebarHours = this.sidebar.querySelector('[data-sidebar-hours]');
      this.sidebarHoursList = this.sidebar.querySelector('[data-sidebar-hours-list]');
      this.ui.sidebarErrorMsg = this.sidebar.querySelector('[data-sidebar-hours-error-text]');

      // Add event listener when event is fetch.
      document.addEventListener('cart:productGet', this.cartProductGetHandler, false);
    },
    cartProductGetHandler(e) {
      // Save dates.
      this.dates.raw = e.detail.dates;

      this.productId = e.detail.productId;

      // Get only days for dates.
      this.dates.onlyDays = this.dates.raw.map(item => {

        // Save day with date as key
        this.dates.objDayAsKey[item.day] = item;

        // Return day.
        return item.day;
      });

      // If we have a selected date on the response (meaning we have already a product inside cart)
      if (e.detail.dateSelected) {
        this.dates.dateSelected = e.detail.dateSelected;
      } else {
        this.dates.dateSelected = {};
      }

      // Destroy datepicker if exists.
      if (this.fp) {
        this.fp.destroy();
      }

      // Construct datepicker.
      this.fp = flatpickr(this.node, {
        ...datepickerConfig(this.options.locale),
        enable: this.dates.onlyDays,
        defaultDate: this.dates.dateSelected ? this.dates.dateSelected.dateStr : null
      });

      // If no date is selected, set the default month to be
      // the month of the first date
      let isSelectedDateEmpty = Object.keys(this.dates.dateSelected).length === 0 && this.dates.dateSelected.constructor === Object;
      if (isSelectedDateEmpty && this.dates.onlyDays && this.dates.onlyDays.length) {
        let firstDate = this.dates.onlyDays[0];
        const rawDate = flatpickr.parseDate(firstDate, 'Z');
        this.fp.changeMonth(rawDate.getMonth(), false)
        this.fp.changeYear(rawDate.getFullYear(), false)
      }

      // Make sure loader has transition.
      this.sidebar.classList.remove('no-transition-loader');

      // Remove loading state on sidebar.
      this.sidebar.classList.remove(this.sidebarLoadingKlass);

      // Listen to date change on datepicker.
      this.fp.config.onChange.push(this.onDateChangeHandler);

      // Build hours.
      this.buildHours();
    },
    formattedDate() {
      // Init formattedDate.
      let formattedDate = null;

      // If if have a selected date.
      if (this.dates.dateSelected && this.dates.dateSelected.dateStr) {

        // Get raw date.
        const rawDate = flatpickr.parseDate(this.dates.dateSelected.dateStr);

        // Get full day
        const fullDay = this.fp.l10n.weekdays.longhand[rawDate.getDay()];

        // Get full date
        const fullDate = rawDate.getDate();

        // Get full month.
        const fullMonth = this.fp.l10n.months.longhand[rawDate.getMonth()];

        // Update formatted date.
        formattedDate = `${fullDay} ${fullDate} ${fullMonth}`;
      }

      // Return formatted date.
      this.dates.dateSelected.dateFormatted = formattedDate;
    },
    onDateChangeHandler(selectedDates, dateStr, instance) {
      // Save dates.
      this.dates.dateSelected.dateRaw = selectedDates;
      this.dates.dateSelected.dateStr = dateStr;

      // Formatted date.
      this.formattedDate();

      // Reset hour.
      this.dates.dateSelected.hourStr = null;

      // Emit event.
      this.emitEvent();

      // Based on type, do something else.
      switch(this.type) {
        case 'cart':
          this.buildHours();
          break;
      }
    },
    buildHoursWithData(hours) {
      // Init markup.
      let markup = '';

      // Loop through each hour for the day selected.
      markup += hours.map(item => {
        return `
          <li class="sidebar__hours-item ${!item.is_available ? 'is-not-available' : ''}">
            <button class="sidebar__hours-btn ${this.dates.dateSelected && this.dates.dateSelected.hourStr === item.start_time && item.is_available ? 'is-selected' : ''}" type="button" ${item.show_id ? `data-show-id="${item.show_id}"` : '' } data-start-date="${item.start_date}" data-datepicker-single-btn>${item.start_time}</button>
          </li>
        `;
      }).join('');

      // Append markup inside sidebar hours.
      this.sidebarHoursList.innerHTML = markup;

      // Show sidebar hours.
      this.sidebarHours.classList.remove(this.sidebarHoursHiddenClass);

      // Get hours buttons.
      this.sidebarHoursBtns = this.sidebar.querySelectorAll('[data-datepicker-single-btn]');

      // If we have some buttons, add event listener.
      if (this.sidebarHoursBtns) {
        Array.from(this.sidebarHoursBtns).map(btn => {
          btn.addEventListener('click', this.handleHourClick, false);
        });
      }
    },
    buildHours() {

      // If we don't have a date yet, return.
      if (!this.dates.dateSelected.dateStr) {

        // Remove event on buttons.
        if (this.sidebarHoursBtns) {
          Array.from(this.sidebarHoursBtns).map(btn => {
            btn.removeEventListener('click', this.handleHourClick);
          });
          this.sidebarHoursBtns = null;
        }

        // Remove content inside sidebar hours list.
        this.sidebarHoursList.innerHTML = '';

        // Hide sidebar hours.
        this.sidebarHours.classList.add(this.sidebarHoursHiddenClass);

        // Return.
        return;
      }

      this.removeAvailabilityErrorMsg();
      this.sidebarHours.classList.remove(this.sidebarHoursHiddenClass);

      let { hours } = this.dates.objDayAsKey[this.dates.dateSelected.dateStr];

      this.buildHoursWithData(hours);

      hours.forEach((hour) =>{
        this.checkHourAvailability(hour.show_id)
      })
    },
    async handleHourClick(e) {
      // Get target btn.
      const targetBtn = e.currentTarget;

      this.removeAvailabilityErrorMsg();

      // Update all btns.
      for (const btn of this.sidebarHoursBtns) {
        if (btn === targetBtn) {

          btn.classList.add('is-selected');

          this.sidebarHours.classList.add('is-loading');
          this.node.dispatchEvent(new CustomEvent('datepicker-single:loading', {
            bubbles: true,
          }));
          const showId = btn.getAttribute('data-show-id') ? btn.getAttribute('data-show-id') * 1 : null
          await this.checkHourAvailability(showId)

          // Let the cart know its child behavior has finished loading
          // so the Next button can be re-enabled
          this.node.dispatchEvent(new CustomEvent('datepicker-single:loaded', {
            bubbles: true,
          }));
          this.sidebarHours.classList.remove('is-loading');
          if(btn.dataset.availability === 'available') {
            // Update selected date data
            this.dates.dateSelected.hourStr = btn.innerText;
            this.dates.dateSelected.showId = btn.getAttribute('data-show-id') ? btn.getAttribute('data-show-id') * 1 : null;
            this.dates.dateSelected.startDate = btn.getAttribute('data-start-date') ? btn.getAttribute('data-start-date') : null;
          } else {
            // Deselect hour
            btn.classList.remove('is-selected');
            this.dates.dateSelected.hourStr = null;
            this.dates.dateSelected.showId = null;
            this.dates.dateSelected.startDate = null;

            this.showAvailabilityErrorMsg(btn.innerText)
          }

        } else {
          btn.classList.remove('is-selected');
        }
      }

      // Emit event.
      this.emitEvent();
    },
    async checkHourAvailability(show_id) {
      return new Promise((resolve, reject) => {
          const hourBtn = Array.from(this.sidebarHoursBtns).find(btn => Number(btn.dataset.showId) === show_id);
          if (!hourBtn) {
            return reject('Hour button not found');
          }
          hourBtn.dataset.availability = 'loading';
          ax({
            method: 'post',
            url: '/api/v1/online-sales/shows/prices-availability',
            data: {
              'shows': show_id
            }
          }).then(res => {

            // Resolve whether the returned data
            // contains at least one price for each show we're about to render...
            let showWithPrice = res.data?.response;

            // The response *might* be empty, so let's short-circuit here if so
            if (!showWithPrice) {
              return reject('No data received');
            }

            for (let [showId, show] of Object.entries(showWithPrice)) {
              if (show?.prices?.length && show.prices[0].totalAvailableSeats > 0) {
                hourBtn.dataset.availability = 'available';
              } else {
                hourBtn.dataset.availability = 'unavailable';
                hourBtn.parentNode.classList.add('is-not-available');
              }
            }
            resolve();

          }).catch(err => {
            reject(err);
          });
      });
    },
    showAvailabilityErrorMsg(hourStr) {
      if(this.ui.sidebarErrorMsg) {
        const msg = this.ui.sidebarErrorMsg.getAttribute('data-sidebar-hours-error-text');
        this.ui.sidebarErrorMsg.textContent = msg.replace('{{hour}}', hourStr);
      }
    },
    removeAvailabilityErrorMsg() {
      if(this.ui.sidebarErrorMsg) {
        this.ui.sidebarErrorMsg.textContent = '';
      }
    },
    emitEvent() {

      // Emit event.
      document.dispatchEvent(new CustomEvent('datepicker:updated', {
        detail: {
          type: this.type,
          dateSelected: this.dates.dateSelected
        }
      }));
    }
  },
  {
    init() {
      this.sidebarLoadingKlass = 'is-loading';
      this.sidebarHoursHiddenClass = 'is-hidden';
      this.fp = null;
      this.type = null;
      this.ui = {};

      // Save dates.
      this.dates = {
        raw: null,
        onlyDays: null,
        objDayAsKey: {},
        selectedDates: null,
        dateStr: null,
        hourStr: null,
        dateSelected: {
          dateRaw: null,
          dateStr: null,
          dateFormatted: null,
          hourStr: null
        }
      };

      this.productId = null;

      // Check type.
      this.checkType();
    },
    destroy() {
      document.removeEventListener('cart:productGet', this.cartProductGetHandler);
    }
  }
);

export default datepickerSingleBehavior;
