/**
 * @prettier
 */

import axios from 'axios';

// Update global config for Axios.
axios.defaults.mode = 'cors';
axios.defaults.cache = 'no-store';
axios.defaults.credentials = 'include';
axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';

// Provide a default instance, so we can add interceptors for debuggin
let ax = axios.create();

// Store a reference to the axios call promise
// so that it is only called once while pending
let sessionRes = null;

async function getSession() {
  let hour = 1000 * 60 * 60;
  let yesterday = Date.now() - hour * 24;
  let currentSession = JSON.parse(window.localStorage.getItem('session'));
  if (currentSession && currentSession.timestamp > yesterday) {
    return currentSession;
  } else {
    try {
      if (!sessionRes) {
        sessionRes = axios({
          method: 'POST',
          url: '/api/v1/online-sales/session',
        });
      }

      let res = await sessionRes;

      let sessionId = res.data['session-id'];
      let session = { id: sessionId, timestamp: Date.now() };
      window.localStorage.setItem('session', JSON.stringify(session));
      sessionRes = null;

      return session;
    } catch (err) {
      sessionRes = null;
      throw new Error(err);
    }
  }
}

ax.interceptors.request.use((config) => {
  return new Promise((resolve) => {
    getSession().then((session) => {
      if (session) {
        config.headers['X-Session-ID'] = `${session.id}`;
      }
      resolve(config);
    });
  });
});

export default ax;
