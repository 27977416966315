/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';

const eventTriggerBehavior = createBehavior(
  'event-trigger',
  {
    bindUI() {
      this.ui = {};
      this.ui.inner = this.getChild('inner');
    },
    bindEvents() {
      this.node.addEventListener('click', this.handleClick);
    },
    unbindEvents() {},
    triggerEvent() {
      this.node.dispatchEvent(
        new CustomEvent(this.options.event, {
          bubbles: true,
          detail: this.payload,
        }),
      );
    },
    handleClick() {
      this.triggerEvent();
    },
  },
  {
    init() {
      this.payload = JSON.parse(this.options.payload);
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default eventTriggerBehavior;
