import createBehavior from '@js/functions/createBehavior.js';
import lockScroll from '@js/utils/lock-scroll.js';

const headerBehavior = createBehavior(
  'header',
  {
    bindUI() {
      this.stickyClass = 'is-sticky';
      this.transformedClass = 'is-transformed';
      this.transitionClass = 'has-transition';
      this.isOpenClass = 'is-nav-open';
      this.isScrollingUp = false;
      this.navToggle = document.querySelectorAll('[data-nav-toggle]');
      this.notification = document.querySelector('.notification');
      this.navAnchor = document.querySelector('[data-behavior="nav-anchor"]');

      // Add timeout to make sure we have the full page render correctly before calculate.
      setTimeout(() => {
        this.headerH = this.node.offsetHeight + 1;
        this.notificationHeight = this.notification ? this.notification.offsetHeight : 0;
        this.headerTop = this.node.getBoundingClientRect().top + window.scrollY + this.notificationHeight;
      }, 300);
    },
    bindEvents() {
      Array.from(this.navToggle).map(item => {
        item.addEventListener('click', this.toggleNav, false);
      });
      document.addEventListener('scrolled', this.scrollHandler, false)
      window.addEventListener('lockScroll:willBeEnable', this.lockScrollWillBeEnableHandler, false);
      window.addEventListener('lockScroll:disable', this.lockScrollDisableHandler, false);

      // Close nav when cart is opened
      document.addEventListener('miniCart:opened', this.closeNav, false)
    },
    lockScrollWillBeEnableHandler() {
      this.isDisable = true;
    },
    lockScrollDisableHandler() {
      this.isDisable = false;

      // Show header.
      this.isScrollingUp = true;
      this.node.classList.add(this.transitionClass);
      this.node.classList.remove(this.transformedClass);
    },
    toggleNav() {
      // Set class on body.
      document.body.classList.toggle(this.isOpenClass);

      // If it's open, lock scroll and emit event
      if (document.body.classList.contains(this.isOpenClass)) {
        document.dispatchEvent(new CustomEvent('nav:opened'));
        lockScroll.enable(350);
      } else {
        document.dispatchEvent(new CustomEvent('nav:closed'));
        lockScroll.disable();
      }
    },
    scrollHandler(e) {
      // If it's disable, return.
      if (this.isDisable) return;

      // Update scroll direction.
      if (e.detail.last < e.detail.prev && !this.isScrollingUp) {
        this.isScrollingUp = true;
      } else if (e.detail.last > e.detail.prev && this.isScrollingUp) {
        this.isScrollingUp = false;
      }

      // Update header.
      this.updateHeader(e.detail.last);
    },
    updateHeader(scrollTop) {
      if (this.isScrollingUp) {

        // Add transition.
        this.node.classList.add(this.transitionClass);

        // Show header.
        this.node.classList.remove(this.transformedClass);
        this.navAnchor?.classList.add('with-visible-header');

        // If we reach the top, remove sticky.
        if (scrollTop === 0) {
          this.node.classList.remove(this.transitionClass);
          this.node.classList.remove(this.stickyClass);
          this.navAnchor?.classList.remove('with-visible-header');
        }

      } else {
        if (scrollTop >= this.headerTop + this.headerH ) {
          this.node.classList.add(this.stickyClass, this.transformedClass);
          this.navAnchor?.classList.remove('with-visible-header');
        }
      }
    },
    closeNav() {
      if (document.body.classList.contains(this.isOpenClass)) {
        document.body.classList.remove(this.isOpenClass);
        lockScroll.disable();
      }
    },
    unbindEvents() {
      Array.from(this.navToggle).map(item => {
        item.removeEventListener('click', this.toggleNav);
      });
      document.removeEventListener('scrolled', this.scrollHandler)
      window.removeEventListener('lockScroll:willBeEnable', this.lockScrollWillBeEnableHandler);
      window.removeEventListener('lockScroll:disable', this.lockScrollDisableHandler);
    }
  },
  {
    init() {
      this.isDisable = false;

      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.closeNav();
      this.unbindEvents();
    }
  }
);

export default headerBehavior;
