import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';

const formDateBehavior = createBehavior(
  'form-date',
  {
    bindUI() {
      this.input = this.getChild('input');
      this.fake = this.getChild('fake');

      this.datepickerTrigger = this.getChild('datepicker-trigger');
      this.datepickerInline = this.getChild('datepicker-inline');
    },
    bindEvents() {
      document.addEventListener('datepicker:updated', this.updateField, false);

      if ( this.datepickerTrigger ) {
        this.datepickerTrigger.addEventListener('click', this.toggleDatepickerInline, false);
      }
    },
    unbindEvents() {
      document.removeEventListener('datepicker:updated', this.updateField);
    },
    getValue() {
      if (this.input.value && this.input.value !== '') {

        // Turn date into raw one.
        const rawDate = flatpickr.parseDate(this.input.value, 'Y-m-d');

        // Init new date.
        let newDate = flatpickr.formatDate(rawDate, 'd/m/Y');

        // Save date format based on user locale.
        // if (this.options['locale'] && this.options['locale'] === 'fr') {
        //   newDate = flatpickr.formatDate(rawDate, 'd/m/Y');
        // } else {
        //   newDate = flatpickr.formatDate(rawDate, 'm/d/Y');
        // }

        // Append new date.
        this.fake.textContent = newDate;

        // Add has-value to element.
        this.node.classList.add('has-value');
      }
    },
    updateField(e) {
      // If it's the node that is updated.
      if (e.detail.field_id === this.options['field-id']) {

        // Input date value inside hidden input.
        this.input.value = e.detail.date;

        // Based on locale.
        this.fake.textContent = e.detail.date_fr;

        // if (e.detail.locale === 'fr' && e.detail.date_fr) {
        //   this.fake.textContent = e.detail.date_fr;
        // } else if (e.detail.date_en) {
        //   this.fake.textContent = e.detail.date_en;
        // } else {
        //   this.fake.textContent = e.detail.date;
        // }

        // Add has-value to element.
        this.node.classList.add('has-value');

        this.toggleDatepickerInline();
      }
    },
    toggleDatepickerInline(e) {
      if ( e ) e.preventDefault();
      this.datepickerInline.classList.toggle('is-hidden');
    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();

      // Get value.
      this.getValue();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default formDateBehavior;
