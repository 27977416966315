import createBehavior from '@js/functions/createBehavior.js';

const cardProductBehavior = createBehavior(
  'card-product',
  {
    bindUI() {
      this.ui = {};

      this.ui.appleWallet = this.getChild('apple-wallet');
      this.ui.googleWallet = this.getChild('google-wallet');
      this.ui.displayWalletDesktop = this.getChild('desktop');
    },
    detectPlatform() {
      // Get user agent.
      const ua = navigator.userAgent.toLowerCase();

      // Get iOS and Android.
      const isIos = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isAndroid = ua.indexOf('android') > -1;

      // Based on values, show correct wallet buttons.
      if ((isIos || this.ui.displayWalletDesktop) && this.ui.appleWallet) {
        this.ui.appleWallet.classList.add('is-visible');
      }
      if ((isAndroid || this.ui.displayWalletDesktop) && this.ui.googleWallet) {
        this.ui.googleWallet.classList.add('is-visible');
      }

    }
  },
  {
    init() {
      this.bindUI();
      this.detectPlatform();
    }
  }
);

export default cardProductBehavior;
