/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import ax from '@js/functions/axios.js';

const productAvailabilityBehavior = createBehavior(
  'product-availability',
  {
    checkAvailability() {
      if (this.productId) {
        this.node.classList.remove('is-availability-error');
        this.node.classList.add('is-availability-loading');
        ax({
          method: 'GET',
          url: `/api/v1/online-sales/products/${this.productId}/availability`,
        })
          .then((res) => {
            this.node.classList.remove('is-availability-loading');
            this.node.classList.toggle('is-full', res.data.response.is_full);
            this.node.classList.toggle(
              'is-available',
              res.data.response.is_available,
            );
          })
          .catch((err) => {
            this.node.classList.remove('is-availability-loading');
            this.node.classList.add('is-availability-error');
          });
      }
    },
  },
  {
    init() {
      this.productId = this.options['product-id'];

      if (!this.productId) {
        console.error(
          'product-availability behavior was not passed a product-id',
        );
      }

      // Make sure axios global settings are set from cart behavior
      setTimeout(() => {
        this.checkAvailability();
      }, 0);
    },
    destroy() {},
  },
);

export default productAvailabilityBehavior;
