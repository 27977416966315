import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';
import { datepickerConfig } from '@js/datepicker/index.js';
import { ajaxRequest } from '@area17/a17-helpers';

const datepickerHoursBehavior = createBehavior(
  'datepicker-hours',
  {
    bindUI() {
      this.sidebar = document.querySelector(`#${this.sidebarId}`);
      this.sidebarDate = this.sidebar.querySelector('[data-sidebar-date]');
    },
    bindEvents() {
      // Add event listener for sidebar open.
      document.addEventListener('sidebar:open', this.sidebarOpenHandler, false);
    },
    sidebarOpenHandler(e) {
      // If it's the target sidebar.
      if (e.detail.id === this.sidebarId && !this.dates.raw) {
        this.fetchDates();
      }
    },
    fetchDates() {
      // If request already exist, abort it.
      if (this.request) {
        this.request.abort();
      }

      // Add is-loading class on sidebar.
      this.sidebar.classList.add(this.sidebarLoadingKlass);

      // Launch new ajax request.
      this.request = ajaxRequest({
        url: this.options['url-api'],
        type: 'GET',
        mode: 'cors',
        cache: 'no-store',
        data: {
          locale: this.options.locale,
        },
        onSuccess: (data) => {

          // Get json.
          const json = JSON.parse(data);

          // If we don't have any error.
          if (!json.error) {

            // Save dates.
            this.dates.raw = json.response;

            // Build datepicker.
            this.buildDatepicker();

          } else {
            console.error(json.message);
          }
        },
        onError: function(data) {
          console.error(data);
        }
      });
    },
    buildDatepicker() {
      // Get only days for dates.
      this.dates.onlyDays = this.dates.raw.items.map(item => {

        // Save day with date as key
        this.dates.objDayAsKey[item.date] = item;

        // Return date.
        return item.date;
      });

      // Cosntruct datepicker.
      this.fp = flatpickr(this.node, {
        ...datepickerConfig(this.options.locale),
        enable: this.dates.onlyDays,
        minDate: this.dates.onlyDays[0],
        maxDate: this.dates.onlyDays[this.dates.onlyDays.length - 1]
      });

      // Remove loading state on sidebar.
      this.sidebar.classList.remove(this.sidebarLoadingKlass);

      // Listen to date change on datepicker.
      this.fp.config.onChange.push(this.onDateChangeHandler)
    },
    onDateChangeHandler(selectedDates, dateStr, instance) {
      // Update selected date.
      this.dates.selectedDate = dateStr;

      // Update sidebar footer.
      this.updatesidebarDate();
    },  
    // Split dateString to year, month and day
    splitDate(dateStr){
      let splittedArray = dateStr.split('-');


      let year = splittedArray[0];
      let month = splittedArray[1] - 1;
      let day = splittedArray[2];


      return [ year, month, day];
    },
    updatesidebarDate() {
      // Get date.
      const date = this.dates.objDayAsKey[this.dates.selectedDate];

      const [year, month, day] = this.splitDate(this.dates.selectedDate);


      // Get raw date.
      const rawDate = new Date(year, month, day);

      // Get full day
      const fullDay = this.fp.l10n.weekdays.longhand[rawDate.getDay()];

      // Get full date
      const fullDate = rawDate.getDate();

      // Get full month.
      const fullMonth = this.fp.l10n.months.longhand[rawDate.getMonth()];

      // Init markup variable.
      let markup = '<p class="sidebar__date-text">';

      // Add day.
      markup += `<span class="sidebar__date-day">${fullDay} ${fullDate} ${fullMonth}</span>`;

      // Based on if it's open or not.
      if (date.is_open) {
        markup += `<span class="sidebar__date-hours">${this.dates.raw.label_open} ${date.hours}</span>`;
      } else {
        markup += `<span class="sidebar__date-hours">${this.dates.raw.label_closed}</span>`;
      }

      // Close markup.
      markup += '</p>';

      // Append markup inside sidebar footer.
      this.sidebarDate.innerHTML = markup;

      // Show sidebar footer.
      this.sidebarDate.classList.remove(this.sidebarDateKlass);
    }
  },
  {
    init() {
      // Utility variables.
      this.sidebarId = 'sidebar-hours';
      this.sidebarLoadingKlass = 'is-loading';
      this.sidebarDateKlass = 'is-hidden';

      // Global variables.
      this.request = null;
      this.fp = null;
      this.dates = {
        raw: null,
        onlyDays: null,
        objDayAsKey: {},
        selectedDate: null
      };

      this.bindUI();
      this.bindEvents();
    }
  }
);

export default datepickerHoursBehavior;
