import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';
import { datepickerConfig } from '@js/datepicker/index.js';

const datepickerCartBehavior = createBehavior(
  'datepicker-cart',
  {
    bindEvents() {
      // Build datepicker once we get the event dates.
      document.addEventListener('datepickerCart:create', this.buildDatepicker, false);
    },
    buildDatepicker(e) {

    }
  },
  {
    init() {
      this.fp = null;
      this.dates = {
        raw: null,
        full: null,
        onlyDays: null
      };

      this.bindEvents();
    }
  }
);

export default datepickerCartBehavior;
