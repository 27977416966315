/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';

const seeMoreBehavior = createBehavior(
  'see-more',
  {
    bindUI() {
      this.ui = {}
      this.ui.moreBtn = this.getChild('button');
    },
    bindEvents() {
      if (this.ui.moreBtn) {
        this.ui.moreBtn.addEventListener(
          'click',
          this.handleMoreBtnClick,
        );
      }
    },
    handleMoreBtnClick(evt) {
      evt.preventDefault();
      this.node.classList.toggle('is-visible-full');
    },
  },
  {
    init() {
      this.bindUI()
      this.bindEvents()
    },
    destroy() {},
  },
);

export default seeMoreBehavior;
