import createBehavior from '@js/functions/createBehavior.js';
import Video from '@components/video/video.twig';

const liveBehavior = createBehavior(
  'live',
  {
    bindUI() {
      this.sticky = this.getChild('sticky');
      this.close = this.getChild('close');
    },
    bindEvents() {
      if (this.close) {
        this.close.addEventListener('click', this.clickHandler, false);
      }

      // Add event listener when page is updated.
      document.addEventListener('page:updated', this.pageUpdatedHandler, false);
    },
    pageUpdatedHandler(e) {
      if (e.detail.next.namespace === 'homepage') {
        if (this.isSticky && this.options['variation'] === 'sticky' && !this.isClosed) {
          this.node.classList.remove(this.visibleKlass);
          setTimeout(() => {
            this.removeLive();
            this.isSticky = false;
            this.node.classList.remove(this.visibleKlass);
          }, this.transitionDuration);
        }
      } else if (!this.isSticky && this.options['variation'] === 'sticky' && !this.isClosed) {
        this.buildPlayer();
        this.isSticky = true;
        this.node.classList.add(this.visibleKlass);
      }
    },
    buildPlayer() {
      this.sticky.innerHTML = Video(JSON.parse(this.options['video']));
    },
    clickHandler() {
      // Remove class.
      this.node.classList.remove(this.visibleKlass);

      // Unbind events.
      this.unbindEvents();

      // Set local storage.
      this.setLocalStorage();

      // Remove node.
      setTimeout(() => {
        this.removeLive();
      }, 300);
    },
    readLocalStorage() {
      // Get local storage.
      let ls = localStorage.getItem('live');

      // If we have some local storage.
      if (ls) {

        // Turn it into object.
        ls = JSON.parse(ls);

        // Loop through each items.
        if (ls.items && ls.items.length) {
          for (let i = 0, j = ls.items.length; i < j; i++) {
            if (ls.items[i].id === this.id) {
              this.isClosed = true;
            }
          }
        }
      }

      // If user hasn't close the live yet, show it.
      if (this.options['variation'] === 'inline') {
        this.buildPlayer();
      } else if (this.options['variation'] === 'sticky' && !this.isHomepage() && !this.isClosed) {
        this.buildPlayer();
        this.node.classList.add(this.visibleKlass);
        this.isSticky = true;
      }
    },
    setLocalStorage() {
      // Create local storage.
      let newLs = {
        items: []
      };

      // Init hasSeen variable.
      let hasSeen = false;

      // Get local storage.
      let ls = localStorage.getItem('live');

      // If we have some local storage.
      if (ls) {

        // Turn it into object.
        ls = JSON.parse(ls);

        // If we have some items.
        if (ls.items && ls.items.length) {
          newLs.items = ls.items;
        }
      }

      // Push new item.
      newLs.items.push({
        id: this.id
      });

      // Set local storage.
      localStorage.setItem('live', JSON.stringify(newLs));
    },
    removeLive() {
      this.sticky.innerHTML = '';
    },
    isHomepage() {
      if (document.querySelector('.tpl-homepage')) {
        return true;
      } else {
        return false;
      }
    },
    unbindEvents() {
      if (this.close) {
        this.close.removeEventListener('click', this.clickHandler);
      }

      // Add event listener when page is updated.
      document.removeEventListener('page:updated', this.pageUpdatedHandler);
    }
  },
  {
    init() {
      this.ls = null;
      this.isClosed = false;
      this.id = this.node.getAttribute('id');
      this.isSticky = false;
      this.visibleKlass = 'is-visible';
      this.transitionDuration = 300;

      this.bindUI();
      this.bindEvents();
      this.readLocalStorage();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default liveBehavior;

