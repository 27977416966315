// BarbaJS page transition
const htmlElement = document.documentElement;

function updateHTMLClass(namespace, prefix, klasses) {
  // The namespace can contain multiple slugs
  namespace.split(' ').map(function(n) {
    klasses.push(prefix + n);
  });

  htmlElement.className = klasses.join(' ').trim();
}

export function setHTMLClassStatus(status) {
  // console.log('BarbaJS - ' + status);
  htmlElement.classList.add('tpl-' + status);
}

export function setHTMLClassNext(namespace) {
  const prefix = 'tpl-to-';
  const klasses = htmlElement.className.split(' ');

  updateHTMLClass(namespace, prefix, klasses);
}

export function setHTMLClassDone(namespace) {
  const prefix = 'tpl-';

  // Remove previous pages classes from the html
  const klasses = htmlElement.className.split(' ').filter(function(klass) {
    const isPage = klass.lastIndexOf(prefix, 0) === 0 ? true : false;
    return !isPage;
  });

  updateHTMLClass(namespace, prefix, klasses);
}
