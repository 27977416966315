import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';
import barba from '@barba/core';
import { datepickerConfig } from '@js/datepicker/index.js';
import getCurrentMediaQuery from '@area17/a17-helpers/src/utility/getCurrentMediaQuery.js';

const datepickerBehavior = createBehavior(
  'datepicker-range',
  {
    bindUI() {
      // last-child is to make sure it get the right container during barbajs transition
      this.barbaContainer = document.querySelector('[data-barba="container"]:last-child');

      // Get openLabel element.
      if (this.barbaContainer) {
        this.openLabel = this.barbaContainer.querySelector('[data-datepicker-range-open-label]');
      } else {
        this.openLabel = document.querySelector('[data-datepicker-range-open-label]');
      }

      // Get close.
      this.container = this.getChild('container');
      this.validate = this.getChild('validate');
      this.close = this.getChild('close');
      this.reset = this.getChildren('reset');
      this.infos = this.getChild('infos');
    },
    getDatesFromUrl() {
      const params = this.getParamsFromUrl();

      // If we have start and end.
      if (params['start'] && params['end']) {

        // Save raw values.
        const rawFromDate = flatpickr.parseDate(params['start'], 'Y-m-d');
        const rawToDate = flatpickr.parseDate(params['end'], 'Y-m-d');

        // Save from and to dates.
        this.dates.from = flatpickr.formatDate(rawFromDate, 'd/m/Y');
        this.dates.to = flatpickr.formatDate(rawToDate, 'd/m/Y');

      } else {

        // Reset dates.
        this.dates = {
          from: null,
          to: null
        };
      }
    },
    buildDatepicker() {
      // Get window width.
      const windowW = window.innerWidth;

      // Construct datepicker.
      this.fp = flatpickr(this.container, {
        ...datepickerConfig(this.options.locale),
        mode: 'range',
        showMonths: windowW <= 899 ? 1 : 2,
        minDate: this.options['show-all-dates'] != null && this.options['show-all-dates'] ? null : 'today'
      });

      // Update datepicker.
      this.updateDatepicker();

      // Update open button
      this.updateOpenButton();
    },
    bindEvents() {
      // Add click event on reset.
      if (this.reset) {
        this.reset.forEach(reset => {
          reset.addEventListener('click', this.resetDatepicker, false);
        });
      }

      // Add click on validate.
      if (this.validate) {
        this.validate.addEventListener('click', this.validateDatepicker, false);
      }

      // Add click on close.
      if (this.close) {
        this.close.addEventListener('click', this.closeDatepicker, false);
      }

      // When we resize the window.
      window.addEventListener('resized', this.resizedHandler, false);

      // When page is udpated.
      document.addEventListener('page:updated', this.pageUpdatedHandler, false);

      // When datepicker change.
      this.fp.config.onChange.push(this.onDateChangeHandler)
    },
    onDateChangeHandler(selectedDates, dateStr, instance) {
      // If we select a from date inside the datepicker.
      if (selectedDates[0]) {
        this.dates.from = flatpickr.formatDate(selectedDates[0], 'd/m/Y');
      } else {
        this.dates.from = null;
      }

      // If we select a to date inside the datepicker.
      if (selectedDates[1]) {
        this.dates.to = flatpickr.formatDate(selectedDates[1], 'd/m/Y');
      } else {
        this.dates.to = null;
      }

      // Update dates infos.
      this.updateDatesInfos(selectedDates);
    },
    updateDatesInfos(selectedDates) {
      // Create string.
      let str = '';

      // If we have a start date or and end date.
      if (this.dates.from || this.dates.to) {

        // If we have a start date.
        if (this.dates.from) {

          // Init from string.
          let fromString = this.formatDateToFullString(selectedDates[0]);

          // Append fromString to string.
          str += fromString;
        }

        // If we have an end date.
        if (this.dates.to) {

          // Init from string.
          let toString = this.formatDateToFullString(selectedDates[1]);

          // Append toString to string.
          str += ` – ${toString}`;
        }
      }

      // Append string into infos.
      this.infos.innerHTML = str;
    },
    formatDateToFullString(date) {
      // Init full string.
      let fullString = '';

      // Get day.
      const day = this.fp.l10n.weekdays.longhand[date.getDay()];

      // Get date.
      const dateNumber = date.getDate();

      // Get month.
      const month = this.fp.l10n.months.longhand[date.getMonth()];

      // Update string.
      fullString = `${day} ${dateNumber} ${month}`;

      // Return full string.
      return fullString;
    },
    validateDatepicker() {
      // Close sidebar.
      document.dispatchEvent(new CustomEvent('sidebar:closeFromOutside'));

      // If we have some dates.
      if (this.dates.from && this.dates.to) {

        // Update url.
        this.updateUrl();

        // Update open button.
        this.updateOpenButton();
      }
    },
    closeDatepicker() {
      // Close sidebar.
      document.dispatchEvent(new CustomEvent('sidebar:closeFromOutside'));

      // Update open button.
      this.updateOpenButton();

      // Update url.
      this.updateUrl();
    },
    resetDatepicker() {
      // Update global variables.
      this.dates.from = null;
      this.dates.to = null;

      // Update datepicker.
      this.updateDatepicker();

      // Close datepicker.
      this.closeDatepicker();
    },
    updateDatepicker() {
      this.fp.setDate([this.dates.from, this.dates.to], true, 'd/m/Y');
    },
    updateOpenButton() {
      // If we have a date.
      if (this.openLabel) {
        if (this.dates.from && this.dates.to) {

          // Get back to row dates.
          const rawFromDate = flatpickr.parseDate(this.dates.from, 'd/m/Y');
          const rawToDate = flatpickr.parseDate(this.dates.to, 'd/m/Y');

          // Format dates correctly.
          this.openLabel.innerHTML = flatpickr.formatDate(rawFromDate, 'd.m') + ' ' + this.options['label-separator'] + ' ' + flatpickr.formatDate(rawToDate, 'd.m');

          // Add isActive class on open.
          this.openLabel.classList.add(this.activeClass);

        } else {

          // Reset wording inside open.
          this.openLabel.innerHTML = this.openLabel.getAttribute('data-datepicker-range-label');

          // Remove isActive class on open.
          this.openLabel.classList.remove(this.activeClass);
        }
      }
    },
    getParamsFromUrl() {
      // Init result.
      let result = {};

      // Get URL query string.
      let params = window.location.search;

      // Remove the '?' character.
      params = params.substr(1);

      // If we don't have params, return empty object.
      if (!params) return result;

      // Split the query parameters.
      let queryParamArray = params.split('&');

      // Iterate over parameter array.
      queryParamArray.forEach((param) => {

        // Split the query parameter over '='
        let item = param.split('=');

        // Push into the object.
        result[item[0]] = decodeURIComponent(item[1]);
      });

      // Return result.
      return result;
    },
    updateUrl(e) {
      // If it's not the sidebar filter date range, return;
      if (e && e.detail && e.detail.id !== 'sidebar-filter-date-range') return;

      // Get url.
      let url = this.options['base-url'];

      // Get params.
      const params = this.getParamsFromUrl();

      // If we have a from and to date
      if (this.dates.from && this.dates.to) {

        // Get back to row dates.
        const rawFromDate = flatpickr.parseDate(this.dates.from, 'd/m/Y');
        const rawToDate = flatpickr.parseDate(this.dates.to, 'd/m/Y');

        // Add start date to params.
        params['start'] = flatpickr.formatDate(rawFromDate, 'Y-m-d');

        // Add end date to params.
        params['end'] = flatpickr.formatDate(rawToDate, 'Y-m-d');

      } else {

        // Delete from url.
        delete params['start'];
        delete params['end'];

        // Replace url with root one.
        url = this.options['root-url'];
      }

      // Remove category param if current url is different than base_url.
      if (this.options['base-url'] !== window.location.origin + window.location.pathname && params['category']) {
        delete params['category'];
      }

      // Init index.
      let index = 0;

      // If we have some params.
      if (Object.keys(params).length !== 0) {
        url += '?';
      }

      // Loop through each param and add it to url.
      for (const item in params) {

        // If we're not on the first one.
        if (index !== 0) {
          url += '&';
        }

        // Add param.
        url += `${item}=${params[item]}`;

        // Update index.
        index++;
      }

      // Update url.
      if (window.location.href !== url) {
        barba.go(url);
      }
    },
    resizedHandler() {
      // Get breakpoint.
      const breakpoint = getCurrentMediaQuery();

      // If we change breakpoint.
      if (breakpoint !== this.lastBreakpoint && this.fp) {

        // Update last breakpoint.
        this.lastBreakpoint = getCurrentMediaQuery();

        // Get window width.
        const windowW = window.innerWidth;

        // Update showMonths config.
        this.fp.set('showMonths', windowW <= 899 ? 1 : 2);
      }
    },
    pageUpdatedHandler() {
      this.bindUI();
      this.getDatesFromUrl();

      this.bindEvents();
      this.updateDatepicker();
      this.updateOpenButton();
    },
    unbindEvents() {
      // Remove click event on reset.
      if (this.reset) {
        this.reset.forEach(reset => {
          reset.removeEventListener('click', this.resetDatepicker);
        });
      }

      // Remove click on close.
      if (this.close) {
        this.close.removeEventListener('click', this.closeDatepicker);
      }

      // Remove click on validate.
      if (this.validate) {
        this.validate.removeEventListener('click', this.validateDatepicker);
      }

      // Remove page updated.
      document.removeEventListener('page:updated', this.pageUpdatedHandler);

      // Remove resized event.
      window.removeEventListener('resized', this.resizedHandler);

      // Destroy flatpickr.
      this.fp.destroy();
    }
  },
  {
    init() {
      this.lastBreakpoint = getCurrentMediaQuery();

      // Init flatpickr instance.
      this.fp = null;

      // Init dates.
      this.dates = {
        from: null,
        to: null
      };

      // Classes for transitions.
      this.openClass = 'is-datepicker-range-open';
      this.activeClass = 'is-active';

      // Launch functions.
      this.bindUI();
      this.getDatesFromUrl();
      this.buildDatepicker();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default datepickerBehavior;
