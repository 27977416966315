import createBehavior from '@js/functions/createBehavior.js';

const footerBehavior = createBehavior(
  'footer',
  {
    bindUI() {
      // Get barba container.
      this.barbaContainer = document.querySelector('[data-barba="container"]:last-child');

      // If we have a barba container.
      if (this.barbaContainer) {
        this.tpl = this.barbaContainer.querySelector('.tpl');
        this.banner = this.barbaContainer.querySelector('.footer__banner');
        this.legalToggle = this.barbaContainer.querySelector('[data-footer-legal-toggle]');
      } else {
        this.tpl = document.querySelector('.tpl');
        this.banner = document.querySelector('.footer__banner');
        this.legalToggle = document.querySelector('[data-footer-legal-toggle]');
      }

      this.raf = null;
    },
    bindEvents() {
      if (this.banner) {
        window.addEventListener('resized', this.getData, false);
        document.addEventListener('scrolled', this.scrollHandler, false);
      }

      if (this.legalToggle) {
        this.legalToggle.addEventListener('click', this.toggleLegal, false);
      }
    },
    getData() {
      // Get data.
      this.bannerTop = this.tpl.offsetHeight - window.innerHeight - 300;

      // Fake scroll event.
      window.dispatchEvent(new CustomEvent('scroll'));
    },
    scrollHandler(e) {
      // If we reach the banner, show it.
      if (e.detail.last >= this.bannerTop) {
        this.banner.classList.add(this.isVisibleClass);
      } else {
        this.banner.classList.remove(this.isVisibleClass);
      }
    },
    toggleLegal() {
      this.node.classList.toggle('is-legals-open');
    },
    unbindEvents() {
      if (this.banner) {
        window.removeEventListener('resized', this.getData);
        document.removeEventListener('scrolled', this.scrollHandler);
      }

      if (this.legalToggle) {
        this.legalToggle.removeEventListener('click', this.toggleLegal);
      }
    }
  },
  {
    init() {
      this.isVisibleClass = 'is-visible';
      this.bannerTop = 0;

      this.bindUI();
      setTimeout(() => {
        this.getData();
      }, 400)
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default footerBehavior;
