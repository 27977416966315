import createBehavior from '@js/functions/createBehavior.js';

const formQuantityBehavior = createBehavior(
  'form-quantity',
  {
    bindUI() {
      this.btns = this.getChildren('btn');
      this.remove = this.getChild('remove');
      this.add = this.getChild('add');
      this.number = this.getChild('number');
      this.input = this.getChild('input');
    },
    bindEvents() {
      Array.from(this.btns).map(btn => {
        btn.addEventListener('click', this.updateQuantity, false);
      });

      // Add event listener to update UI from outside.
      document.addEventListener('formQuantity:updateFromOutside', this.updateFromOutside, false);
    },
    updateQuantity(e) {
      // Get increment.
      const increment = e.currentTarget.getAttribute('data-increment');

      // Update quantity.
      switch(increment) {
        case 'remove':
          this.quantity--;
          break;
        case 'add':
          this.quantity++;
          break;
      }

      // Update UI.
      this.updateUI();

      // Create new event and dispatch it.
      const event = new CustomEvent('change');
      this.input.dispatchEvent(event);
    },
    updateUI() {
      // Update quantity.
      this.number.innerText = this.quantity;

      // Update remove btn.
      if (this.quantity === 0) {
        this.remove.classList.add('is-disable');
      } else {
        this.remove.classList.remove('is-disable');
      }

      // Update add btn.
      if (this.maximum && this.quantity >= this.maximum) {
        this.add.classList.add('is-disable');
      } else {
        this.add.classList.remove('is-disable');
      }

      // Update input.
      this.input.value = this.quantity;
    },
    updateFromOutside() {
      // Get value inside input.
      this.quantity = this.input.value * 1;

      // Update UI.
      this.updateUI();
    },
    unbindEvents() {
      Array.from(this.btns).map(btn => {
        btn.removeEventListener('click', this.updateQuantity);
      });

      // Remove event listener to update UI from outside.
      document.removeEventListener('formQuantity:updateFromOutside', this.updateFromOutside);
    }
  },
  {
    init() {
      this.quantity = this.options['quantity'] ? this.options['quantity'] * 1 : 0;
      this.maximum = this.options['maximum'] ? this.options['maximum'] * 1 : null;

      this.bindUI();
      this.bindEvents();
      this.updateUI();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default formQuantityBehavior;
