import createBehavior from '@js/functions/createBehavior.js';

const cookieBehavior = createBehavior(
  'cookie',
  {
    bindUI() {

    },
    bindEvents() {

    },
    unbindEvents() {

    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default cookieBehavior;
