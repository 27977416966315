/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import Bricklayer from 'bricklayer';
import WorksImageTemplate from '@components/works-image/works-image.twig';

const worksGridCascading = createBehavior(
  'works-grid-cascading',
  {
    bindUI() {
      this.ui = {};
      this.ui.body = this.getChild('body');
      this.ui.showMoreBtn = this.getChild('show-more-btn');
    },
    bindEvents() {
      document.addEventListener(
        'load-more:items',
        this.handleLoadMoreItems,
        false,
      );
      document.addEventListener(
        'page:beforeEnter',
        this.pageBeforeEnterHandler,
        false,
      );
    },
    cacheDimensions() {},
    updateUI() {},
    unbindEvents() {
      document.removeEventListener('load-more:items', this.handleLoadMoreItems);
      document.removeEventListener('page:beforeEnter', this.pageBeforeEnterHandler);
    },
    handleLoadMoreItems(evt) {
      evt.preventDefault();
      let newItems = [];
      let loadedItems = evt.detail.page.works.items;

      // Append extra items directly to bricklayer
      for (let item of loadedItems) {
        let div = document.createElement('div');
        newItems.push(div);
        div.classList.add('works-grid-cascading__item');
        div.classList.add('is-entering');
        div.innerHTML = WorksImageTemplate({ item, lazy: false });
        this.bricklayer.append(div);
      }

      setTimeout(() => {
        for (let item of newItems) {
          item.classList.remove('is-entering');
        }
      }, 0);

    },
    pageBeforeEnterHandler() {
      this.bricklayer.redraw();
    },
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
      this.cacheDimensions();
      this.updateUI();
      this.bricklayer = new Bricklayer(this.ui.body);
    },
    destroy() {
      document.removeEventListener(
        'page:beforeEnter',
        this.pageBeforeEnterHandler,
      );
      this.unbindEvents();
    },
  },
);

export default worksGridCascading;
