import createBehavior from '@js/functions/createBehavior.js';

const tooltipBehavior = createBehavior(
  'tooltip',
  {
    bindUI() {
      this.ui = {};

      this.ui.btn = this.getChild('btn');
      this.ui.close = this.getChild('close');
      this.ui.content = this.getChild('content');
      this.ui.contentContainer = this.getChild('content-container');
    },
    bindEvents() {
      document.addEventListener('keydown', this.keydownHandler, false);
      this.node.addEventListener('mouseleave', this.mouseLeaveHandler, false);
      this.ui.close.addEventListener('click', this.close, false);
      this.ui.btn.addEventListener('mouseenter', this.mouseenterHandler, false);
      this.ui.btn.addEventListener('focus', this.focusHandler, false);
      this.ui.btn.addEventListener('blur', this.blurHandler, false);
    },
    keydownHandler(e) {
      // If we press escape.
      if (e.keyCode === 27 && this.isOpen) {

        // Remove focus on button.
        if (document.activeElement) {
          document.activeElement.blur();
        }

        // Close tooltip.
        this.close();
      }
    },
    mouseLeaveHandler() {
      // If button is not focus with tab, close tooltip.
      if (!this.isFocus) {
        this.close();
      }
    },
    mouseenterHandler() {
      this.open();
    },
    focusHandler() {
      // If it's focus with tabbed.
      if (document.body.classList.contains('is-tabbed')) {

        // Update isFocus state.
        this.isFocus = true;

        // Open tooltip.
        this.open();
      }
    },
    blurHandler() {
      // Update isFocus state.
      this.isFocus = false;

      // Close tooltip.
      this.close();
    },
    open() {
      // Update isOpen state.
      this.isOpen = true;

      // Update content style.
      this.updateContentStyles();

      // Update UI.
      this.updateUI();
    },
    close() {
      // Update isOpen state.
      this.isOpen = false;

      // Update UI.
      this.updateUI();

      // Remove style attribute.
      this.node.classList.remove('tooltip--bottom');
      this.ui.content.removeAttribute('style');
      this.ui.contentContainer.removeAttribute('style');
    },
    updateUI() {
      if (this.isOpen) {
        this.node.classList.add('is-open');
      } else {
        this.node.classList.remove('is-open');
      }
    },
    updateContentStyles() {
      // Get positions.
      const positions = this.node.getBoundingClientRect();

      // If content doesn't fit on the top.
      if (positions.y < this.ui.content.offsetHeight + 32) {
        this.node.classList.add('tooltip--bottom');
      }

      // Get content position
      const contentPositions = this.ui.contentContainer.getBoundingClientRect();

      // Get if content is cut.
      if (contentPositions.x < 16) {
        this.ui.contentContainer.style.transform = `translateX(${Math.abs(contentPositions.x) + 16}px)`;
      } else if (contentPositions.x + contentPositions.width + 16 > window.innerWidth) {
        this.ui.contentContainer.style.transform = `translateX(-${Math.abs(window.innerWidth - (contentPositions.x + contentPositions.width)) + 16}px)`;
      }
    },
    unbindEvents() {
      document.removeEventListener('keydown', this.keydownHandler);
      this.node.removeEventListener('mouseleave', this.mouseLeaveHandler);
      this.ui.close.removeEventListener('click', this.close);
      this.ui.btn.removeEventListener('mouseenter', this.mouseenterHandler);
      this.ui.btn.removeEventListener('focus', this.focusHandler);
      this.ui.btn.removeEventListener('blur', this.blurHandler);
    },
  },
  {
    init() {
      this.isFocus = false;
      this.isOpen = false;

      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default tooltipBehavior;
