import createBehavior from '@js/functions/createBehavior.js';
import getCurrentMediaQuery from '@area17/a17-helpers/src/utility/getCurrentMediaQuery.js';

const bannerBehavior = createBehavior(
  'banner',
  {
    bindUI() {
      this.scroll = this.getChild('scroll');
      this.list = this.getChild('list');
      this.items = this.getChildren('item');
      this.header = document.querySelector('.header');
    },
    bindEvents() {
      window.addEventListener('resized', this.resizedHandler, false);
      document.addEventListener('page:leave', this.leaveHandler, false);
      document.addEventListener('scrolled', this.scrollHandler, false);
    },
    getData() {
      this.bannerHeight = this.node.offsetHeight;
      this.headerHeight = this.header ? this.header.offsetHeight : 0;
      this.itemW = this.items[0].offsetWidth;
    },
    resizedHandler() {
      // Get breakpoint.
      const breakpoint = getCurrentMediaQuery();

      // If we change breakpoint.
      if (breakpoint !== this.lastBreakpoint) {
        this.lastBreakpoint = breakpoint;
        this.getData();
        this.toggleRaf();
      }
    },
    scrollHandler(e) {
      // If we already have a timeout, return.
      if (this.timeout) return;

      // If we reach the end of the banner.
      if (e.detail.last > this.bannerHeight + this.headerHeight) {

        // Set timeout.
        this.timeout = setTimeout(() => {
          document.body.classList.add('is-banner-hidden');
          cancelAnimationFrame(this.raf);
          this.raf = null;
        }, 1);
      }
    },
    leaveHandler() {
      document.body.classList.remove('is-banner-hidden');
    },
    toggleRaf() {
      if (window.innerWidth >= 900 && !this.raf) {
        this.raf = window.requestAnimationFrame(this.updateBanner);
      } else if (this.raf && window.innerWidth < 900) {
        cancelAnimationFrame(this.raf);
        this.raf = null;
      }
    },
    updateBanner() {
      // Update translate value.
      this.translateValue = this.translateValue + 0.75;

      // Apply transform to the scroll element.
      this.scroll.style.transform = `translate3d(-${this.translateValue}px, 0, 0)`;

      // If we reach the end of an item width.
      if (this.translateValue >= this.itemW) {
        this.translateValue = 0;
        this.list.appendChild(this.items[0]);
        this.items = this.getChildren('item');
      }

      // Relaunch this function.
      this.raf = requestAnimationFrame(this.updateBanner);
    },
    unbindEvents() {
      cancelAnimationFrame(this.raf);
      window.removeEventListener('resized', this.resizedHandler);
      document.removeEventListener('page:leave', this.leaveHandler, false);
      document.removeEventListener('scrolled', this.scrollHandler)
    }
  },
  {
    init() {
      this.translateValue = 0;
      this.animateClass = 'is-animating';
      this.raf = null;
      this.timeout = null;
      this.lastBreakpoint = getCurrentMediaQuery();

      this.bindUI();
      this.getData();
      this.bindEvents();
      this.toggleRaf();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default bannerBehavior;
