/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import ImageTemplate from '@components/image/image.twig';
import { ResizeObserver } from '@juggle/resize-observer';

function fragmentFromString(strHTML) {
  return document.createRange().createContextualFragment(strHTML);
}

// based on http://processing.org/reference/javadoc/core/processing/core/PApplet.html#map(float, float, float, float, float)
var scale = function (opts) {
  var istart = opts.domain[0],
    istop = opts.domain[1],
    ostart = opts.range[0],
    ostop = opts.range[1];

  return function scale(value) {
    return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
  };
};

const heroArtworkBehavior = createBehavior(
  'hero-artwork',
  {
    bindUI() {
      this.ui = {};
      this.ui.imageContainer = this.getChild('image-container');
      this.ui.imageContainerInner = this.getChild('image-container-inner');
      this.ui.zoomContainer = this.getChild('zoom-container');
      this.ui.zoomContainerInner = this.getChild('zoom-container-inner');
      this.ui.zoomInner = this.getChild('zoom-inner');
      this.ui.zoomMask = this.getChild('zoom-mask');
      this.ui.zoomImage = this.ui.zoomContainer.querySelector('img');
      this.ui.zoomCursor = this.getChild('zoom-cursor');
      this.ui.thumbs = this.getChildren('thumb');
      this.ui.thumbsArr = Array.from(this.ui.thumbs);
      this.ui.captions = document.querySelectorAll('.caption span[data-caption-index]')
    },
    bindEvents() {
      this.ui.zoomMask.addEventListener(
        'mousemove',
        this.handleZoomMaskMousemove,
      );
      this.zoomContainerResizeObserver = new ResizeObserver((entries) => {
        this.cacheDimensions();
        requestAnimationFrame(this.updateUI);
      });
      this.zoomContainerResizeObserver.observe(this.ui.zoomContainer);
      this.ui.zoomImage.addEventListener('load', this.handleZoomImageLoaded);
      this.ui.zoomMask.addEventListener('click', this.handleZoomMaskClick);
      this.ui.zoomMask.addEventListener(
        'mouseover',
        this.handleZoomMaskMouseover,
      );
      this.ui.zoomMask.addEventListener(
        'mouseout',
        this.handleZoomMaskMouseout,
      );
      for (let image of this.ui.thumbsArr) {
        image.addEventListener('click', this.handleThumbClick);
      }
    },
    unbindEvents() {
      this.zoomContainerResizeObserver.unobserve(this.ui.zoomContainer);
    },
    cacheDimensions() {
      this.zoomContainerDimensions.width = this.ui.zoomContainer.offsetWidth;
      this.zoomContainerDimensions.height = this.ui.zoomContainer.offsetHeight;
      this.zoomImageDimensions.width = this.ui.zoomImage.offsetWidth;
      this.zoomImageDimensions.height = this.ui.zoomImage.offsetHeight;
      this.scrollXScale = scale({
        domain: [0, this.zoomContainerDimensions.width],
        range: [
          0,
          this.zoomImageDimensions.width - this.zoomContainerDimensions.width,
        ],
      });
      this.scrollYScale = scale({
        domain: [0, this.zoomContainerDimensions.height],
        range: [
          0,
          this.zoomImageDimensions.height - this.zoomContainerDimensions.height,
        ],
      });
    },
    handleZoomImageLoaded() {
      this.cacheDimensions();
    },
    handleZoomMaskMousemove(evt) {
      this.zoomContainerMouseOffset.x = evt.offsetX;
      this.zoomContainerMouseOffset.y = evt.offsetY;
      requestAnimationFrame(this.updateUI);
    },
    handleZoomMaskMouseover() {
      this.node.classList.add('is-hovered');
    },
    handleZoomMaskMouseout() {
      this.node.classList.remove('is-hovered');
    },
    handleZoomMaskClick() {
      if (this.node.classList.contains('is-zoomed')) {
        this.node.classList.remove('is-zoomed');
      } else {
        this.node.classList.add('is-zoomed');
      }
    },
    preloadImage(el) {
      return new Promise((resolve, reject) => {
        if (el.complete) {
          resolve();
        } else {
          let handleLoad = () => {
            el.removeEventListener('load', handleLoad);
            resolve();
          };
          el.addEventListener('load', handleLoad);
        }
      });
    },
    handleThumbClick(evt) {
      evt.preventDefault();

      for (let thumb of this.ui.thumbsArr) {
        const isCurrent = thumb === evt.currentTarget;
        thumb.classList.toggle('is-current', isCurrent);
        this.ui.captions.forEach((el, index) => {
          if(isCurrent == index) {
            el.classList.add('is-active')
          } else {
            el.classList.remove('is-active')
          }
        })
      }

      if (!evt.currentTarget.dataset.heroArtworkThumb) {
        console.error('No image data provided in the HTML');
        return;
      }

      let json = JSON.parse(evt.currentTarget.dataset.heroArtworkThumb);

      if (!json.standard || !json.zoom) {
        // console.log('standard and zoom images must be provided in the HTML');
        return;
      }

      this.node.classList.remove('is-zoomed');

      let imageHTML = ImageTemplate({
        ...json.standard,
        lazy: false,
      });

      // Standard image
      let imageFragment = fragmentFromString(imageHTML);
      let existingContent = this.ui.imageContainerInner.children;
      let existingContentArr = Array.from(existingContent);
      let newImage = imageFragment.querySelector('.image');
      let newImageImg = newImage.querySelector('img');

      // Zoom image
      let zoomImageHTML = ImageTemplate({
        ...json.zoom,
        lazy: false,
      });
      let zoomExistingContent = Array.from(this.ui.zoomContainerInner.children);
      let zoomImageFragment = fragmentFromString(zoomImageHTML);
      let newZoomImage = zoomImageFragment.querySelector('.image');
      let newZoomImageImg = newZoomImage.querySelector('img');
      for (let el of zoomExistingContent) {
        el.remove();
      }

      this.ui.zoomContainerInner.appendChild(newZoomImage);
      newImageImg.classList.add('is-loading');
      this.ui.imageContainerInner.appendChild(newImage);

      this.ui.imageContainerInner.classList.add('is-fading');
      let timeoutPromise = new Promise((resolve) =>
        setTimeout(() => resolve(), 300),
      );

      this.preloadImage(newZoomImageImg).then(() => {
        this.bindUI();
        this.cacheDimensions();
      });

      Promise.all([this.preloadImage(newImageImg), timeoutPromise]).then(() => {
        newImageImg.classList.remove('is-loading');

        for (let el of existingContentArr) {
          el.remove();
        }

        this.ui.imageContainerInner.classList.remove('is-fading');
        this.cacheDimensions();
      });
    },
    updateUI() {
      let x = this.zoomContainerMouseOffset.x;
      let y = this.zoomContainerMouseOffset.y;
      this.ui.zoomInner.scrollLeft = this.scrollXScale(x);
      this.ui.zoomInner.scrollTop = this.scrollYScale(y);
      this.ui.zoomCursor.style.transform = `translate(${x}px, ${y}px)`;
    },
  },
  {
    init() {
      this.zoomContainerMouseOffset = { x: 0, y: 0 };
      this.zoomContainerDimensions = { width: 0, height: 0 };
      this.zoomImageDimensions = { width: 0, height: 0 };
      this.bindUI();
      this.cacheDimensions();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default heroArtworkBehavior;
