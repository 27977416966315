import createBehavior from '@js/functions/createBehavior.js';
import scrollToY from '@area17/a17-helpers/src/utility/scrollToY.js';

const navAnchorBehavior = createBehavior(
  'nav-anchor',
  {
    bindUI() {
      this.links = this.getChildren('link');
    },
    bindEvents() {
      Array.from(this.links).map(link => {
        link.addEventListener('click', this.clickHandler, false);
      });

      // simple function to use for callback in the intersection observer
      const changeNav = (entries, observer) => {
        const intersectingTitles = entries.filter(entry => entry.isIntersecting);
        const lastTitleOnScreen = intersectingTitles[intersectingTitles.length - 1];

        const id = lastTitleOnScreen?.target?.getAttribute('id');

        if (id) {
          Array.from(this.links).map(link => {
            link.parentElement.classList.toggle('is-active', link.getAttribute('href') === '#' + id);
          });
        }
      }

      const observer = new IntersectionObserver(changeNav);

      document.querySelectorAll('.block-title').forEach((section) => {
        observer.observe(section);
      });
    },
    unbindEvents() {
      Array.from(this.links).map(link => {
        link.removeEventListener('click', this.clickHandler);
      });
    },
    clickHandler(e) {
      // Prevent default.
      e.preventDefault();

      // Get href.
      const href = e.currentTarget.getAttribute('href');

      // Get target element.
      const targetEl = document.querySelector(href);

      // If target element exists, scroll to.
      if (targetEl) {
        scrollToY({
          el: document,
          offset: targetEl.getBoundingClientRect().top + window.scrollY - 75,
          duration: 500,
          easing: 'easeInOut'
        });

        Array.from(this.links).map(link => {
          link.parentElement.classList.remove('is-active')
        });

        e.currentTarget.parentElement.classList.add('is-active');
      }
    },
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default navAnchorBehavior;
