import createBehavior from '@js/functions/createBehavior.js';
import HangsListing from '@components/hangs-listing/hangs-listing.twig';

const hangsListing = createBehavior(
  'hangs-listing',
  {
    bindUI() {
      this.ui = {};
      this.ui.body = this.getChild('body');
      this.ui.showMoreBtn = this.getChild('show-more-btn');
    },
    bindEvents() {
      document.addEventListener(
        'load-more:items',
        this.handleLoadMoreItems,
        false,
      );
    },
    cacheDimensions() {},
    updateUI() {},
    unbindEvents() {
      document.removeEventListener(
        'load-more:items',
        this.handleLoadMoreItems
      );
    },
    render(hangs) {
      hangs.years = hangs.years.map(y => ({ ...y, title: y.title.toString() }))
      const hangsListingItems = HangsListing({ hangs });
      this.ui.body.insertAdjacentHTML('beforeend', hangsListingItems);
    },
    handleLoadMoreItems(evt) {
      if (evt.detail.page.hangs.years) {
        this.render(evt.detail.page.hangs)
      }

      // Update next url
      if (evt.detail.page.pagination.next_url) {
        this.node.setAttribute('data-load-more-next-url', `${evt.detail.page.pagination.next_url}`)
      }

      document.dispatchEvent(new CustomEvent('page:updated'))
    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default hangsListing;
