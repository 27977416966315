import createBehavior from '@js/functions/createBehavior.js';
import WorksGridItem from '@components/works-grid/works-grid-item.twig';

const worksGrid = createBehavior(
  'works-grid',
  {
    bindUI() {
      this.ui = {};
      this.ui.body = this.getChild('body');
      this.ui.groupSlug = this.options['group-slug'];
      this.ui.showMoreBtn = this.getChild('show-more-btn');
    },
    bindEvents() {
      document.addEventListener(
        'load-more:items',
        this.handleLoadMoreItems,
        false,
      );
    },
    cacheDimensions() {},
    updateUI() {},
    unbindEvents() {
      document.removeEventListener(
        'load-more:items',
        this.handleLoadMoreItems
      );
    },
    render(items) {
      const worksGridItems = WorksGridItem({ items, lazy: false });
      this.ui.body.insertAdjacentHTML('beforeend', worksGridItems);
    },
    handleLoadMoreItems(evt) {
      if (evt.detail.node !== this.node) return;

      // Related artworks
      if (evt.detail.page.related_artworks) {
        let relatedArtworks = evt.detail.page.related_artworks;
        let activeTab = relatedArtworks.tabs.filter(tab => tab.is_active)
        if (activeTab.length > 0) {
          let activeGroup = relatedArtworks.groups.filter(group => {
            return activeTab[0].label == group.title
          })

          if (activeGroup.length > 0) {
            this.render(activeGroup[0].items)
          }
        }
      }

      // Update next url
      if (evt.detail.page.pagination.next_url) {
        this.node.setAttribute('data-load-more-next-url', `${evt.detail.page.pagination.next_url}&hangTab=${this.ui.groupSlug}`)
      }

      // Works
      if (evt.detail.page.works) {
        this.render(evt.detail.page.works.items)
      }

      // items
      if (evt.detail.page.items) {
        this.render(evt.detail.page.items)
      }

      document.dispatchEvent(new CustomEvent('page:updated'))
    }
  },
  {
    init() {
      this.bindUI();
      this.bindEvents();
    },
    destroy() {
      this.unbindEvents();
    },
  },
);

export default worksGrid;
