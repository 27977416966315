import createBehavior from '@js/functions/createBehavior.js';

const detectTabBehavior = createBehavior(
  'detect-tab',
  {
    bindEvent() {
      document.addEventListener('keydown', this.handleKeyDown, false);
    },
    handleKeyDown() {
      // If tabulation is pressed
      if (event.keyCode !== 9) {
        return;
      }

      document.body.classList.add('is-tabbed');
      document.removeEventListener('keydown', this.handleKeyDown);
      document.addEventListener('mousedown', this.handleMouseDown);
    },
    handleMouseDown() {
      document.body.classList.remove('is-tabbed');
      document.addEventListener('keydown', this.handleKeyDown);
      document.removeEventListener('mousedown', this.handleMouseDown);
    },
    unbindEvents() {
      document.removeEventListener('keydown', this.handleKeyDown);
    }
  },
  {
    init() {
      this.bindEvent();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default detectTabBehavior;
