import createBehavior from '@js/functions/createBehavior.js';
import flatpickr from 'flatpickr';
import { datepickerConfig } from '@js/datepicker/index.js';

const datepickerBehavior = createBehavior(
  'datepicker',
  {
    bindEvents() {
      this.fp.config.onChange.push(this.onDateChangeHandler)
    },
    onDateChangeHandler(selectedDates, dateStr, instance) {
      document.dispatchEvent(new CustomEvent('datepicker:updated', {
        detail: {
          field_id: this.options['field-id'],
          date: dateStr,
          date_fr: flatpickr.formatDate(selectedDates[0], 'd/m/Y'),
          date_en: flatpickr.formatDate(selectedDates[0], 'm/d/Y'),
          locale: this.options['locale']
        }
      }));
    },
    buildDatepicker() {
      // Init dates variable.
      let dates = null;

      // If we have some dates.
      if (this.options['dates'] && this.options['dates'] !== '') {
        dates = JSON.parse(this.options['dates'])
      }

      // Create object for options.
      const options = {
        ...datepickerConfig(this.options.locale),
        defaultDate: this.options['date-selected'] ? this.options['date-selected'] : null,
        minDate: this.options['date-selected'] || this.options['date-minimum'] || new Date(),
      }

      // If we have some dates.
      if (dates) {
        options.enable = dates;
      }

      // Construct datepicker.
      this.fp = flatpickr(this.node, options);
    }
  },
  {
    init() {
      this.fp = null;
      this.buildDatepicker();
      this.bindEvents();
    }
  }
);

export default datepickerBehavior;
