import createBehavior from '@js/functions/createBehavior.js';

const notificationBehavior = createBehavior(
  'notification',
  {
    bindUI() {
      this.container = this.getChild('container');
      this.close = this.getChild('close');
    },
    bindEvents() {
      this.close.addEventListener('click', this.clickHandler, false);
      window.addEventListener('resized', this.updateHeight, false);
    },
    unbindEvents() {
      this.close.removeEventListener('click', this.clickHandler);
      window.removeEventListener('resized', this.updateHeight);
    },
    readLocalStorage() {
      // Init hasSeen notification variable.
      let hasSeen = false;

      // Get local storage.
      let ls = localStorage.getItem('notification');

      // If we have some local storage.
      if (ls) {

        // Turn it into object.
        ls = JSON.parse(ls);

        // Loop through each items.
        if (ls.items && ls.items.length) {
          for (let i = 0, j = ls.items.length; i < j; i++) {
            if (ls.items[i].id === this.id) {
              hasSeen = true;
            }
          }
        }
      }

      // If user hasn't seen the notification yet, show it.
      if (!hasSeen) {
        this.isVisible = true;
        this.showNotification();
      }
    },
    setLocalStorage() {
      // Create local storage.
      let newLs = {
        items: []
      };

      // Init hasSeen variable.
      let hasSeen = false;

      // Get local storage.
      let ls = localStorage.getItem('notification');

      // If we have some local storage.
      if (ls) {

        // Turn it into object.
        ls = JSON.parse(ls);

        // If we have some items.
        if (ls.items && ls.items.length) {
          newLs.items = ls.items;
        }
      }

      // Push new item.
      newLs.items.push({
        id: this.id
      });

      // Set local storage.
      localStorage.setItem('notification', JSON.stringify(newLs));

      // Update variable.
      this.isVisible = false;
    },
    showNotification() {
      // Update height.
      this.updateHeight();

      // Add class on node.
      setTimeout(() => {
        this.node.classList.add(this.transitionClass);
      }, 50);
    },
    updateHeight() {
      if (this.isVisible) {
        // Get height from container.
        const heightContainer = this.container.offsetHeight;

        // Adjust height of the notification.
        this.node.style.height = heightContainer + 'px';
      }
    },
    hideNotification() {
      // Set height to 0 on node.
      this.node.style.height = '0px';

      // Once it's done, emit event.
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('page:updated'));
        this.setLocalStorage();
      }, 350);
    },
    clickHandler(e) {
      // Hide notification.
      this.hideNotification();
    }
  },
  {
    init() {
      this.ls = null;
      this.id = this.node.getAttribute('id');
      this.isVisible = false;
      this.transitionClass = 'has-transition';

      this.bindUI();
      this.bindEvents();
      this.readLocalStorage();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default notificationBehavior;
