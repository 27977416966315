import createBehavior from '@js/functions/createBehavior.js';
import lockScroll from '@js/utils/lock-scroll.js';

const sidebarBehavior = createBehavior(
  'sidebar',
  {
    bindUI() {
      this.links = document.querySelectorAll(`a[href="#${this.id}"]`);
      this.closes = this.getChildren('close');
      this.header = document.querySelector('[data-behavior="header"]');

    },
    bindEvents() {
      // Add event listener for esc touch.
      document.addEventListener('keydown', this.keyboardHandler, false);

      // Add event listener.
      document.addEventListener('sidebar:closeFromOutside', this.closeFromOutside, false);

      // Add event listener when page is updated.
      document.addEventListener('page:updated', this.pageUpdatedHandler, false);

      // Add click event on links.
      if (this.links) {
        Array.from(this.links).map(link => {
          link.addEventListener('click', this.clickLinkHandler, false);
        });
      }

      // Add click event on closes.
      Array.from(this.closes).map(close => {
        close.addEventListener('click', this.toggleSidebar, false);
      });

      this.node.addEventListener('cart-sidebar:open', this.handleCartSidebarOpen, false);
    },
    pageUpdatedHandler() {
      // Remove click event on links.
      if (this.links) {
        Array.from(this.links).map(link => {
          link.removeEventListener('click', this.clickLinkHandler);
        });
      }

      // Remove click event on closes.
      Array.from(this.closes).map(close => {
        close.removeEventListener('click', this.toggleSidebar);
      });

      // Bind UI.
      this.bindUI();

      // Add click event on links.
      if (this.links) {
        Array.from(this.links).map(link => {
          link.addEventListener('click', this.clickLinkHandler, false);
        });
      }

      // Add click event on closes.
      Array.from(this.closes).map(close => {
        close.addEventListener('click', this.toggleSidebar, false);
      });
    },
    keyboardHandler(e) {
      // If it's not open, return.
      if (!this.isOpen) return;

      // If we press escape.
      if (e.keyCode === 27) {
        this.toggleSidebar();
      }
    },
    clickLinkHandler(e) {
      // Prevent default.
      e.preventDefault();

      // If it's the sidebar cart.
      if (this.node.getAttribute('id') === 'sidebar-cart' && !this.isOpen) {
        this.node.classList.add('no-transition-loader');
      }

      // Toggle sidebar.
      this.toggleSidebar(e);
    },
    toggleSidebar(e) {
      // If page is locked, return.
      if (document.documentElement.classList.contains('is-page-locked')) return;

      if (this.isOpen) {
        this.close();
      } else {
        this.open(e);
        this.header.classList.add('is-transformed');
      }

      // Update isOpen variable.
      this.isOpen = !this.isOpen;

      // Update accessibility.
      this.updateAccessibility();

      // Force REOPEN if membership renew from within the sidebar
      if (!this.isOpen && e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.productMembership == 'renew') {
        this.toggleSidebar(e);
      }
    },
    open(e) {
      // Remove no-transition class.
      setTimeout(() => {
        this.node.classList.remove('no-transition-loader');
      }, 400);

      // Init step variable.
      let step = null;

      // Get event id.
      if (e.currentTarget) {
        this.productId = e.currentTarget.getAttribute('data-product-id');
        this.productType = e.currentTarget.getAttribute('data-product-type');
        this.productMembership = e.currentTarget.getAttribute('data-product-membership');
        this.productIsDigital = 'productIsDigital' in e.currentTarget.dataset;
        this.productIsGift = 'productIsGift' in e.currentTarget.dataset;
        this.productCanBeGift = 'productCanBeGift' in e.currentTarget.dataset;
        this.productGiftMinAge = e.currentTarget.getAttribute('data-product-gift-min-age');
        this.productGiftMaxAge = e.currentTarget.getAttribute('data-product-gift-max-age');
        step = e.currentTarget.getAttribute('data-step') ? e.currentTarget.getAttribute('data-step') * 1 : null;
      }

      if (e.detail && e.detail.step) {
        step = e.detail.step;
      }

      // Show sidebar.
      this.node.classList.add(this.openClass);

      // Lock scroll when sidebar is open.
      lockScroll.enable(400);

      // Emit event to launch actions on other behaviors.
      document.dispatchEvent(new CustomEvent('sidebar:open', {
        detail: {
          id: this.node.getAttribute('id'),
          productId: this.productId,
          productType: this.productType,
          productMembership: this.productMembership,
          productIsDigital: this.productIsDigital,
          productIsGift: this.productIsGift,
          productCanBeGift: this.productCanBeGift,
          productGiftMinAge: this.productGiftMinAge,
          productGiftMaxAge: this.productGiftMaxAge,
          step: step,
          shouldCheckAmount: e.detail && e.detail.shouldCheckAmount,
        }
      }));

      // Fix data for membership cart
      let membership = document.querySelector('article.membership');
      if ( membership ) {
        let renewIdMissing = document.querySelectorAll('[data-product-membership="renew"]');
        renewIdMissing.forEach( ( item ) => {
          if (membership.dataset.productRenewId) {
            item.dataset.productId = membership.dataset.productRenewId;
          }
        } );
      }

    },
    close() {
      // Remove class on sidebar.
      this.node.classList.remove(this.openClass);

      // Enable event.
      lockScroll.disable();

      // Emit event.
      document.dispatchEvent(new CustomEvent('sidebar:close', {
        detail: {
          id: this.node.getAttribute('id')
        }
      }));

      // Reset event id.
      this.eventId = null;
    },
    closeFromOutside() {
      this.node.classList.remove(this.openClass);
      lockScroll.disable();
      this.isOpen = false;
      this.eventId = null;
    },
    updateAccessibility() {
      if (this.isOpen) {
        this.node.setAttribute('aria-hidden', false);
        this.node.removeAttribute('tabindex');
        this.node.style.visibility = null;
      } else {
        this.node.setAttribute('aria-hidden', true);
        this.node.setAttribute('tabindex', -1);
        this.node.style.visibility = 'hidden';
      }
    },
    handleCartSidebarOpen(e) {
      this.isOpen = true;
      this.open(e);
    },
    unbindEvents() {
      document.removeEventListener('keydown', this.keyboardHandler);

      document.removeEventListener('sidebar:closeFromOutside', this.closeFromOutside);

      document.removeEventListener('page:updated', this.pageUpdatedHandler);

      Array.from(this.links).map(link => {
        link.removeEventListener('click', this.clickLinkHandler);
      });

      Array.from(this.closes).map(close => {
        close.removeEventListener('click', this.toggleSidebar);
      });

      this.node.removeEventListener('cart-sidebar:open', this.handleCartSidebarOpen);
    },
  },
  {
    init() {
      this.openClass = 'is-open';
      this.id = this.node.getAttribute('id');
      this.isOpen = this.node.classList.contains(this.openClass);
      this.eventId = null;

      this.bindUI();
      this.bindEvents();
      this.updateAccessibility();
    },
    destroy() {
      this.unbindEvents();
    }
  }
);

export default sidebarBehavior;
