/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';
import ax from '@js/functions/axios.js';

const loadMoreBehavior = createBehavior(
  'load-more',
  {
    bindUI() {
      this.ui = {}
      this.ui.showMoreBtn = this.getChild('show-more-btn');
    },
    bindEvents() {
      if (this.ui.showMoreBtn) {
        this.ui.showMoreBtn.addEventListener(
          'click',
          this.handleShowMoreBtnClick,
        );
      }
    },
    handleShowMoreBtnClick(evt) {
      evt.preventDefault();
      this.ui.showMoreBtn.setAttribute('disabled', true)
      let nextUrl = this.node.getAttribute('data-load-more-next-url')
      // Load data from backend an input here
      ax({
        method: 'GET',
        url: `${nextUrl}&output=json`
      }).then((res) => {
        // fetched results
        document.dispatchEvent(new CustomEvent('load-more:items', {
          detail: {
            page: res.data.page,
            node: this.node
          }
        }));

        // if no more data remove the button
        if (!res.data.page.pagination.next_url) this.ui.showMoreBtn.remove();

        this.ui.showMoreBtn.removeAttribute('disabled')
      }).catch((err) => {
        this.ui.showMoreBtn.removeAttribute('disabled')
      })
    },
  },
  {
    init() {
      this.bindUI()
      this.bindEvents()
    },
    destroy() {},
  },
);

export default loadMoreBehavior;
