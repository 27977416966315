import { French } from 'flatpickr/dist/l10n/fr.js';
import { Italian } from 'flatpickr/dist/l10n/it.js';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import { German } from 'flatpickr/dist/l10n/de.js';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';

// Function to get the corresponding config file based on language.
export function datepickerGetLocale(lang) {
  // Init local variable.
  let locale = null;

  // Based on options value.
  switch(lang) {
    case 'fr':
      locale = French;
      break;
    case 'it':
      locale = Italian;
      break;
    case 'es':
      locale = Spanish;
      break;
    case 'de':
      locale = German;
      break;
    case 'ja':
      locale = Japanese;
      break;
    default:
      locale = null;
      break;
  }

  // Return locale.
  return locale;
}

// SVG for the arrows used inside the datepicker.
export const datepickerPrevArrow = '<svg class="icon icon--chevron-left" width="16" height="16" viewBox="0 0 16 16"><polygon fill="currentColor" points="9.601 2.5 10.803 3.702 6.405 8.101 10.803 12.5 9.601 13.702 4 8.101"/></svg>';
export const datepickerNextArrow = '<svg class="icon icon--chevron-right" width="16" height="16" viewBox="0 0 16 16"><polygon fill="currentColor" points="4.899 3.702 6.101 2.5 11.702 8.101 6.101 13.702 4.899 12.5 9.297 8.101"/></svg>';

// Default config options for all datepickers.
export function datepickerConfig(lang) {
  return {
    locale: datepickerGetLocale(lang),
    monthSelectorType: 'static',
    yearSelectorType: 'static',
    inline: true,
    prevArrow: datepickerPrevArrow,
    nextArrow: datepickerNextArrow
  }
}
