/**
 * @prettier
 */

import createBehavior from '@js/functions/createBehavior.js';

const modalVideoBehavior = createBehavior(
  'modal-video',
  {
    bindUI() {
      this.ui = {};
      this.ui.btnClose = this.getChild('close');
      this.ui.iframe = this.getChild('iframe');
      this.ui.body = document.documentElement.querySelector('body');
    },
    bindEvents() {

      this.ui.btnClose.addEventListener('click', this.closeHandler, false);

      // Listen to card video
      document.addEventListener('card-video:play', this.onVideoPlay, false);
    },
    onVideoPlay(e) {
      this.node.style.setProperty( '--video-ratio', 200 - (e.detail.ratio * 100) + '%' );
      if ( this.ui.iframe ) this.ui.iframe.src = e.detail.src;
      this.node.classList.add('is-visible');
      this.ui.body.classList.add('has-modal');
    },
    closeHandler() {
      if ( this.ui.iframe ) this.ui.iframe.src = "";
      this.node.classList.remove('is-visible');
      this.ui.body.classList.remove('has-modal');
    }
  },
  {
    init() {
      this.bindUI()
      this.bindEvents()
    },
    destroy() {},
  },
);

export default modalVideoBehavior;
